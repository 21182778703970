import React from 'react';

import {
  Box,
  IconButton,
  Toolbar,
  Typography
} from '@material-ui/core';

import {
  Close as CloseIcon,
  Edit as EditIcon,
  Settings as SettingsIcon,
  DeleteForever as DeleteIcon
} from '@material-ui/icons';

import API, {
  DataCollection,
  DataCollectionInput,
  DataCollectionObject
} from '../api';

import {
  ContentDialog,
  DataCollectionBadge,
  DataCollectionEditor,
  DataCollectionIcon,
  DataCollectionForm,
  DataCollectionSkeleton,
  DebugDialog,
  ErrorBox,
  ImageContainer,
  ImagePreview,
  ImageTile,
  ImageZoomControl,
  PaginationToolbar,
  ResourceEditDialog,
  ResourceDeleteDialog
} from '../components';

import {
  loadDataCollectionObjectsPage
} from '../utils';

interface Props {
  dataCollectionId: string;
  actions?: React.ReactNode;
  style?: React.CSSProperties;
  onClose?: () => void;
  onUpdate?: (dataCollection: DataCollection) => void;
  onDelete?: (dataCollection: DataCollection) => void;
}

class State {
  columns: number = 0;
  loading: boolean = true;
  paginating: boolean = false;
  error?: Error;
  dataCollection?: DataCollection;
  objects?: Array<DataCollectionObject>;
  locked: boolean = true;
  pageIndex: number = 0;
  imagePreview?: DataCollectionObject;
}

export class DataCollectionViewer extends React.Component<Props, State> {

  private containerRef = React.createRef<HTMLDivElement>();

  readonly state = new State();

  onPageIndexChange = (dataCollectionId: string, pageIndex: number) => {
    this.setState({ paginating: true, error: undefined, pageIndex });
    loadDataCollectionObjectsPage(dataCollectionId, pageIndex)
    .then(objects => this.setState({ paginating: false, objects}))
    .catch(error => this.setState({ paginating: false, error }))
  }

  componentDidMount() {
    const { dataCollectionId } = this.props;
    this.setState({ loading: true, error: undefined, dataCollection: undefined })
    Promise.all([
      API.dataCollections.getDataCollection(dataCollectionId),
      loadDataCollectionObjectsPage(dataCollectionId)
    ])
    .then(([dataCollection, objects]) => {
      // TODO: check if data collection is locked
      this.setState({ loading: false, dataCollection, objects, locked: false });
    })
    .catch(error => this.setState({ loading: false, error }))
  }

  render() {
    const { actions, style, onClose, onUpdate, onDelete } = this.props;
    const { dataCollection, objects, pageIndex, locked, loading, paginating, error, columns, imagePreview } = this.state;

    if (loading)
      return <DataCollectionSkeleton aspectRatio={1} columns={8} imagesCount={20} onClose={onClose} />;

    if (error || !dataCollection || !objects)
      return <ErrorBox message={error?.message} onReload={() => this.componentDidMount()} />

    const { numberOfObjects } = dataCollection;
    const { aspectRatio } = objects[0]?.attributes || {};

    return (
      <Box style={style} flex={1} display="flex" flexDirection="column">
        <Toolbar>
          <DataCollectionIcon />
          <Box mx={1} />
          <Typography variant="h6">{dataCollection.name}</Typography>
          <DataCollectionBadge dataCollection={dataCollection} style={{marginLeft: 8}} />
          <Box mx={2} />
          <DebugDialog object={dataCollection} />
          <ResourceEditDialog<DataCollectionInput, DataCollection>
            title="Basic Settings"
            resource={{...dataCollection}}
            trigger={<IconButton><SettingsIcon /></IconButton>}
            action={dataCollectionInput => API.dataCollections.updateDataCollection(dataCollection.id, dataCollectionInput) }
            onSuccess={dataCollection => {
              this.setState({ dataCollection });
              onUpdate?.(dataCollection);
            }}
            renderForm={(dataCollectionInput, ref, handleSubmit) => (
              <DataCollectionForm
                dataCollectionInput={dataCollectionInput}
                innerRef={ref}
                onSubmit={handleSubmit} />
            )}
          />
          <ContentDialog
            fullscreen
            trigger={<IconButton><EditIcon /></IconButton>}
            renderContent={(close) => (
              <DataCollectionEditor
                dataSourceReferenceId={dataCollection.dataSourceReferenceId!}
                dataCollectionId={dataCollection.id}
                onUpdate={dataCollection => {
                  this.setState({ dataCollection });
                  this.onPageIndexChange(dataCollection.id, pageIndex);
                  onUpdate?.(dataCollection);
                }}
                onClose={close}
              />
            )}
          />
          { !locked &&
            <ResourceDeleteDialog<DataCollection>
              resource={dataCollection}
              title="Delete Data Collection ?"
              trigger={<IconButton><DeleteIcon/></IconButton>}
              action={resource => API.dataCollections.deleteDataCollection(resource.id) }
              onSuccess={dataCollection => {
                onDelete?.(dataCollection);
                onClose?.();
              }}
            />
          }
          { actions }
          <Box mx="auto" />
          { onClose &&
            <IconButton
              onClick={() => {
                onClose?.();
              }}>
              <CloseIcon />
            </IconButton>
          }
        </Toolbar>
        { imagePreview &&
          <ContentDialog
            size="lg"
            renderContent={(close) => (
              <ImagePreview
                icon={<DataCollectionIcon/>}
                object={imagePreview}
                onClose={() => this.setState({ imagePreview: undefined })}
              />
            )}
          />
        }
        <Box display="flex" flexDirection="row-reverse" style={{flex: '1 1 1px', overflowY: 'hidden'}}
          paddingX={2.5}>
          <Box style={{flex: '1 1 1px', overflowY: 'scroll'}} { ...{ref: this.containerRef}}>
            { numberOfObjects === 0 &&
              <Box display="flex" p={1} justifyContent="center"><Typography variant="body2" color="textSecondary">No images uploaded yet.</Typography></Box>
            }
            { paginating &&
              <Box style={{flex: '1 1 1px', overflowY: 'scroll'}}>
                <ImageContainer
                  columns={columns}
                  aspectRatio={aspectRatio}
                >
                  {Array(100).fill(0).map((val, index) => (
                    <ImageTile key={index} />
                  ))}
                </ImageContainer>
              </Box>
            }
            { !paginating &&
              <ImageContainer
                columns={columns}
                aspectRatio={aspectRatio}
              >
                { objects.map((image, index) => (
                  <ImageTile
                    key={index}
                    image={image}
                    onClick={() => this.setState({ imagePreview: image })}
                  />
                ))}
              </ImageContainer>
            }
          </Box>
          <Box>
            <ImageZoomControl
              containerRef={this.containerRef}
              columns={columns}
              onChange={columns => {
                this.setState({ columns });
              }}
            />
          </Box>
        </Box>
        <PaginationToolbar
          pageIndex={pageIndex}
          totalItems={numberOfObjects}
          onPageIndexChange={pageIndex => this.onPageIndexChange(dataCollection.id, pageIndex)}
        />
      </Box>
    )
  }
}