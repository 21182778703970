import React from 'react';

import {
  TSelectableItemProps,
  createSelectable
} from 'react-selectable-fast'

import {
  Skeleton
} from '@material-ui/lab';

import {
  TRBLLabel,
  DataCollectionObject,
  LabelsetEntry,
  LabelConfiguration
} from '../api';

import {
  ImageTileOverlay
} from '../components';

export interface ImageTileProps {
  image?: DataCollectionObject;
  labelsetEntry?: LabelsetEntry;
  labelConfiguration?: LabelConfiguration;
  cropBox?: TRBLLabel;
  selectable?: boolean;
  standalone?: boolean;
  style?: React.CSSProperties;
  onClick?: () => void;
  onSelection?: (selected: boolean) => void;
  onLoad?: (target: HTMLImageElement) => void;
}

class State {
  loading: boolean = true;
}

export const ImageTile = createSelectable(class extends React.Component<ImageTileProps & TSelectableItemProps, State> {

  private imageRef = React.createRef<HTMLImageElement>();

  readonly state = new State();

  render() {
    const { image, labelsetEntry, style, labelConfiguration, onClick, onLoad, selectable, standalone, cropBox } = this.props;
    const { selectableRef, isSelected, isSelecting } = this.props;
    const { loading } = this.state;

    const crop = cropBox || { left: 0, top: 0, right: 1, bottom: 1};

    let imageSrc = undefined;
    let imageStyles = {};
    if (image) {
      imageSrc = image.attributes[standalone ? 'previewUrl' : 'thumbnailUrl'];
      const width = 1 / (crop.right - crop.left);
      const height = 1 / (crop.bottom - crop.top);
      imageStyles = {
        cursor: onClick ? 'pointer' : undefined,
        position: 'absolute',
        left: `${-crop.left * width * 100}%`,
        top: `${-crop.top * height * 100}%`,
        width: `${width * 100}%`,
        height: `${height * 100}%`,
        display: loading ? 'none' : undefined
      }
    }

    return (
      <li
        ref={selectableRef}
        style={{
          flexShrink: 0,
          padding: 0,
          margin: 0,
          listStyle: "none",
          position: "relative",
          overflow: "hidden",
          border: standalone ? 'none' : '2px solid transparent',
          boxSizing: "border-box",
          ...style
        }}
      >
        <div style={{
          width: '100%',
          height: '100%',
          position: 'absolute',
          overflow: 'hidden',
          left: 0,
          top: 0,
          boxSizing: "border-box",
          borderStyle: standalone ? 'none' : (isSelected ? 'ridge' : isSelecting ? 'dashed' : 'solid'),
          borderColor: isSelected || isSelecting ? 'red' : 'transparent',
          borderWidth: standalone ? 0 : (selectable ? 3 : 3)
        }}>
          <React.Fragment>
          { loading && <Skeleton variant="rect" width="100%" height="100%" /> }
            {<img
              src={imageSrc}
              title={standalone ? undefined : image?.attributes?.originalFilename}
              style={imageStyles}
              ref={this.imageRef}
              onLoad={evt => {
                const target = this.imageRef.current;
                this.setState({loading: false})
                if (onLoad && target) {
                  onLoad(target);
                }
              }}
              onError={evt => {
                this.setState({loading: false})
              }}
              onClick={evt=>{
                if (onClick) {
                  onClick();
                }
              }}
            />}
          </React.Fragment>
        </div>
        { labelsetEntry &&
          <ImageTileOverlay
            cropBox={cropBox}
            labelsetEntry={labelsetEntry}
            labelConfiguration={labelConfiguration}
            standalone={standalone}
          />
        }
      </li>
    )
  }
});