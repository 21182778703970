import React from 'react';

import {
  ButtonGroup,
  Button,
  Box,
  Divider,
  FormControlLabel,
  IconButton,
  Popover,
  Switch,
  Toolbar,
  Typography
} from '@material-ui/core';

import {
  InfoOutlined as InfoIcon,
  TabUnselected as LabelIcon,
} from '@material-ui/icons';

import {
  Alert,
  AlertTitle,
  Pagination
} from '@material-ui/lab';

import {
  DataCollectionObject,
  Labelset,
  LabelsetEntry,
  Label,
  TRBLLabel,
  deepClone,
} from '../api';

import {
  ImageCanvas
} from '../components';

import {
  replaceLabelsetEntries,
  syncLabelsetEntries,
  lookupLabelDefinitionColor,
  updateLabelsetEntries,
  updateLabels
} from '../utils';

interface Props {
  labelset: Labelset;
  objects: Array<DataCollectionObject>;
  selectedEntries: Array<LabelsetEntry>;
  onUpdate: (updates: Array<LabelsetEntry>) => void;
  onClose: () => void;
}

class State {
  selectedIndex: number = 0;
  updates: LabelsetEntry[];
  selectedRegions: Label[] = [];
  copiedRegions: Label[] = [];
  syncLabels: boolean = false;
  infoAnchor: HTMLButtonElement | null = null;

  constructor(props: Props) {
    this.updates = deepClone(props.selectedEntries);
  }
}

export class LabelsetEntryAnnotator extends React.Component<Props, State> {

  readonly state = new State(this.props);

  componentDidMount(){
    document.addEventListener("keydown", this.handleKeyDown);

    this.handleNextAnnotationLabel();
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyDown);
  }

  handlePreviousAnnotation() {
    const { selectedIndex, updates } = this.state;
    const newIndex = selectedIndex === 0 ? updates.length - 1 : selectedIndex - 1;
    this.setState({ selectedIndex: newIndex, selectedRegions: [] });
    this.handleNextAnnotationLabel();
  }

  handleNextAnnotation() {
    const { selectedIndex, updates } = this.state;
    const newIndex = selectedIndex === updates.length - 1 ? 0 : selectedIndex + 1;
    this.setState({ selectedIndex: newIndex, selectedRegions: [] });
    this.handleNextAnnotationLabel();
  }

  handlePreviousAnnotationLabel() {
    const { updates, selectedRegions, selectedIndex } = this.state;
    const entry = updates[selectedIndex];

    const labels = entry.labels.sort((a, b) => {
      const labelAnnotation1 = a.labelAnnotation as TRBLLabel;
      const labelAnnotation2 = b.labelAnnotation as TRBLLabel;
      return Math.hypot(labelAnnotation1.left, labelAnnotation1.top) - Math.hypot(labelAnnotation2.left, labelAnnotation2.top);
    });
    const index = labels.indexOf(selectedRegions[0]);

    this.setState({
      selectedRegions:
        index === -1 || index === 0
          ? labels.slice(-1)
          : labels.slice(index-1, index)
    })
  }

  handleNextAnnotationLabel() {
    const { updates, selectedRegions, selectedIndex } = this.state;
    const entry = updates[selectedIndex];

    const labels = entry.labels.sort((a, b) => {
      const labelAnnotation1 = a.labelAnnotation as TRBLLabel;
      const labelAnnotation2 = b.labelAnnotation as TRBLLabel;
      return Math.hypot(labelAnnotation1.left, labelAnnotation1.top) - Math.hypot(labelAnnotation2.left, labelAnnotation2.top);
    });
    const index = labels.indexOf(selectedRegions[0]);

    this.setState({
      selectedRegions:
        index === -1 || index === labels.length - 1
          ? labels.slice(0, 1)
          : labels.slice(index+1, index+2)
    })
  }

  applyLabelDefinition(labelIndex: number) {
    const { labelDefinitions } = this.props.labelset.labelConfiguration;
    const { selectedRegions, updates, selectedIndex } = this.state;

    if (selectedRegions.length === 0)
      return;

    let label = labelDefinitions[labelIndex];

    this.setState({
      updates: updateLabelsetEntries(updates, updates[selectedIndex], selectedRegions, label),
      selectedRegions: updateLabels(selectedRegions, label)
    })
  }

  toggleLabelDefinition() {
    const { labelDefinitions } = this.props.labelset.labelConfiguration;
    const { selectedRegions } = this.state;

    const currentLabelId = selectedRegions[0]?.labelId;
    let nextLabelIndex = 0;
    if (currentLabelId !== undefined) {
      const currentLabelIndex = labelDefinitions.findIndex(ld => ld.id === currentLabelId);
      if (currentLabelIndex !== -1)
        nextLabelIndex = currentLabelIndex === labelDefinitions.length - 1 ? 0 : currentLabelIndex + 1;
    }
    this.applyLabelDefinition(nextLabelIndex);
  }

  deleteSelection() {
    const { updates, selectedRegions, selectedIndex } = this.state;
    const entry = updates[selectedIndex];
    const updatedEntry = {
      ...entry,
      labels: entry.labels.filter(l => !selectedRegions.includes(l))
    }
    this.setState({
      updates: replaceLabelsetEntries(updates, [updatedEntry]),
      selectedRegions: []
    });
  }


  copySelection = () => {
    const { selectedRegions } = this.state;
    if (selectedRegions.length) {
      this.setState({ copiedRegions: deepClone(selectedRegions) })
    }
  }

  pasteSelection = () => {
    const { copiedRegions, updates, selectedIndex } = this.state;
    if (copiedRegions.length) {
      const entry = updates[selectedIndex];
      const copies = deepClone(copiedRegions);
      const updatedEntry = {
        ...entry,
        labels: [...entry.labels, ...copies]
      }
      this.setState({
        updates: replaceLabelsetEntries(updates, [updatedEntry]),
        selectedRegions: copies
      });
    }
  }

  handleKeyDown = (evt: KeyboardEvent) => {
    const { key, ctrlKey, metaKey } = evt;

    const control = ctrlKey || metaKey;


    if (key === 'Enter') {
      this.applyChanges();
    }
    else if (key === 'Escape') {
      this.cancelChanges();
    }
    else if (key === ' ') {
      this.toggleLabelDefinition();
    }
    else if (key === 'Delete') {
      this.deleteSelection();
    }
    else if (key === 'ArrowLeft') {
      this.handlePreviousAnnotation();
    }
    else if (key === 'ArrowRight') {
      this.handleNextAnnotation();
    }
    else if (key === 'ArrowUp') {
      this.handlePreviousAnnotationLabel();
    }
    else if (key === 'ArrowDown') {
      this.handleNextAnnotationLabel();
    }
    else if (key.match(/^([0-9])$/)) {
      this.applyLabelDefinition(parseInt(key) - 1);
    }
    else if (control && key === 'c') {
      this.copySelection();
    }
    else if (control && key === 'v') {
      this.pasteSelection();
    }
  }

  applyChanges() {
    const { onUpdate, onClose } = this.props;
    const { updates } = this.state;
    onUpdate(updates);
    onClose();
  }

  cancelChanges() {
    const { onClose } = this.props;
    onClose();
  }

  render () {
    const { labelset, objects } = this.props;
    const { infoAnchor, selectedIndex, updates, selectedRegions, syncLabels } = this.state;

    const entry = updates[selectedIndex];
    const object = objects.find(o => o.id === entry.datasetObjectId)!;

    const areaOfInterest = labelset.additionalAttributes?.areaOfInterest;

    return (
      <Box display="flex" flexDirection="column" flex={1}>
        <Toolbar variant="dense">
          <LabelIcon />
          <Box mx={1} />
          <Typography variant="h6">{ object.attributes.originalFilename }</Typography>
          <Box mx="auto" />
          <ButtonGroup>
            { labelset.labelConfiguration.labelDefinitions.map((labelDefinition, labelIndex) => {
              const [ color ] = lookupLabelDefinitionColor(labelDefinition, labelIndex);
              const countLabels = selectedRegions.filter(l => l.labelId === labelDefinition.id);
              const disabled = selectedRegions.length === 0;
              const selected = countLabels.length > 0 && countLabels.length === selectedRegions.length;
              const indeterminate = countLabels.length > 0 && countLabels.length < selectedRegions.length;
              return (
                <Button key={labelDefinition.id}
                  size="small"
                  disabled={disabled}
                  variant={selected ? 'contained' : 'outlined' }
                  style={{
                    borderColor: (selected ? 'inherit' : color),
                    color: disabled ? '#DDD' : (selected ? '#FFF' : color),
                    background: selected ? color : (indeterminate ? '#DDD' : '#FFF')
                  }}
                  onClick={() => {
                    this.applyLabelDefinition(labelIndex);
                  }}
                >{labelDefinition.name}</Button>
              );
            })}
          </ButtonGroup>
          <Box mx="auto" />
          <Popover
            open={!!infoAnchor}
            anchorEl={infoAnchor}
            onClose={evt => this.setState({ infoAnchor: null })}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <Alert severity="info"
              onClose={evt => this.setState({ infoAnchor: null })}
            >
              <AlertTitle>Keyboard Shortcuts</AlertTitle>
              <b>Left</b>: Previous Image<br/>
              <b>Right</b>: Next Image<br/>
              <b>Up</b>: Previous Region<br/>
              <b>Down</b>: Next Region<br/>
              <b>Space</b>: Toggle Label<br/>
              { labelset.labelConfiguration.labelDefinitions.map((label, index) => (
                <React.Fragment key={index}>
                  <b>{index + 1}</b>: {label.name}<br/>
                </React.Fragment>
              )) }
              <b>Ctrl+C</b>: Copy Region(s)<br/>
              <b>Ctrl+V</b>: Paste Region(s)<br/>
              <b>Escape</b>: Cancel changes<br/>
              <b>Enter</b>: Apply changes
            </Alert>
          </Popover>
          <IconButton
            onClick={evt => this.setState({ infoAnchor: evt.currentTarget})}
          >
            <InfoIcon />
          </IconButton>
        </Toolbar>
        <Divider />
        <Box display="flex" flex={1} flexDirection="row">
          <ImageCanvas
            key={entry.datasetObjectId}
            image={object}
            labelConfiguration={labelset.labelConfiguration}
            labelsetEntry={entry}
            aspectRatio={object.attributes.aspectRatio}
            cropBox={areaOfInterest}
            selection={selectedRegions}
            onAddition={(annotation, additions) => {
              this.setState({ updates: syncLabelsetEntries(updates, annotation, syncLabels ? additions : [], [])});
            }}
            onDeletion={(annotation, deletions) => {
              // NOTE: disable deletion for now because it removes overlapping regions
              // this.setState({ updates: syncLabelsetEntries(updates, annotation, [], syncLabels ? deletions : [])});
            }}
            onChange={annotation => {
              this.setState({ updates: replaceLabelsetEntries(updates, [annotation])})
            }}
            onSelection={selection => this.setState({ selectedRegions: selection})}
            style={{flex: 1, justifyContent: 'center', display: 'flex'}}
          />
        </Box>
        <Divider />
        <Toolbar variant="dense">
          <FormControlLabel
            control={
              <Switch
                checked={syncLabels}
                onChange={evt => this.setState({ syncLabels: evt.target.checked })}
                color="primary"
              />
            }
            label={<Typography variant="subtitle1" children="Sync new Regions" />}
          />
          <Box mx="auto" />
          <Pagination
            page={selectedIndex+1}
            count={updates.length}
            shape="rounded"
            onChange={(e, page) => {
              this.setState({ selectedIndex: page-1, selectedRegions: [] });
              this.handleNextAnnotationLabel();
            }}
          />
          <Box mx="auto" />
          <Button
            onClick={() => this.cancelChanges()}>
            Cancel
          </Button>
          <Box mx={1} />
          <Button
            variant="contained"
            color="primary"
            onClick={evt => this.applyChanges() }
          >
            Save
          </Button>
        </Toolbar>
      </Box>
    );
  }
}