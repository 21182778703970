
import React from 'react';

import {
  Chip
} from '@material-ui/core';

import {
  LocalOfferOutlined as EntriesIcon
} from '@material-ui/icons';

import {
  Labelset,
  Dataset
} from '../api';

export type LabelsetBadgeProps = {
  labelset: Labelset;
  dataset: Dataset;
  style?: React.CSSProperties;
}

export const LabelsetBadge = ({
  style,
  labelset,
  dataset
}: LabelsetBadgeProps) => {

  const { numberOfEntries } = labelset;
  const { numberOfObjects } = dataset;

  if (numberOfEntries === undefined || numberOfObjects === undefined)
    return null;

  const styles = {
    color: numberOfEntries < numberOfObjects ? 'red' : undefined,
    ...style
  }

  return (
    <Chip
      size="small"
      icon={
        <EntriesIcon
          fontSize="small"
          style={{color: styles.color}}
        />
      }
      disabled
      style={styles}
      label={`${numberOfEntries} / ${numberOfObjects}`}
    />
  );
}