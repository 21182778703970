import React from 'react';

import {
  Box,
  Toolbar,
  Typography
} from '@material-ui/core';

import {
  Pagination
} from '@material-ui/lab';

export type PaginationToolbarProps = {
  pageIndex: number;
  totalItems: number;
  itemsPerPage?: number;
  onPageIndexChange: (pageIndex: number) => void;
  small?: boolean;
}

export const PaginationToolbar = ({
  pageIndex,
  totalItems,
  itemsPerPage = 100,
  onPageIndexChange,
  small
}: PaginationToolbarProps) => {

  if (totalItems <= itemsPerPage)
    return null;

  const countPages = Math.ceil(totalItems / itemsPerPage * 1.0);
  const fromIndex = itemsPerPage * pageIndex;
  const toIndex = fromIndex + Math.min(itemsPerPage, (totalItems - fromIndex)) - 1;

  const label = `Images ${fromIndex+1}-${toIndex+1} of ${totalItems}`;

  const pagination = <Pagination
    color="secondary"
    count={countPages}
    page={pageIndex + 1}
    onChange={(evt, page) => onPageIndexChange(page - 1)}
  />

  return small ? pagination : (
    <Toolbar variant="dense">
      <Typography variant="body2">{label}</Typography>
      <Box mx="auto" />
      { pagination }
    </Toolbar>
  );
}