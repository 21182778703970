import React from 'react';

import {
  Box,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  Typography,
  Avatar
} from '@material-ui/core';

import {
  Error as ErrorIcon
} from '@material-ui/icons';

import {
  Skeleton
} from '@material-ui/lab';

import API, {
  DataCollectionObject,
  Dataset,
  Labelset
} from '../api';

import {
  LabelDefinitionChip,
  LabelsetBadge,
  LabelsetIcon,
  LabelsetMenu,
} from '../components';

interface Props  {
  item: Labelset;
  dataset: Dataset;
  selected?: boolean;
  onClick: () => void;
  onUpdate: (labelset: Labelset) => void;
  onDelete: (labelset: Labelset) => void;
}

class State {
  objects?: Array<DataCollectionObject>;
  loading: boolean = true;
  error?: Error;
}

const avatarStyle = { height: 45, width: 45, backgroundColor: '#E3E3E3' };

export class LabelsetItemSkeleton extends React.Component {
  render() {
    return (
      <ListItem style={{paddingTop: 8, paddingBottom: 8}}>
        <ListItemAvatar>
          <Skeleton style={avatarStyle} variant="rect"  />
        </ListItemAvatar>
        <ListItemText
          primary={<Skeleton height={20} width={150} />}
          secondary={<Skeleton height={15} width={200} />}
        />
      </ListItem>
    );
  }
}

export class LabelsetItem extends React.Component<Props, State> {

  readonly state = new State();

  componentDidMount() {
    const { dataset } = this.props;
    API.datasets.getDatasetVersionObjects(dataset.datasetVersionId, { take: 10 })
    .then(objects => this.setState({ loading: false, objects }))
    .catch(error => this.setState({ loading: false, error }))
  }

  render() {
    const { item, dataset, selected, onClick, onUpdate, onDelete } = this.props;
    const { objects, loading } = this.state;

    return (
      <React.Fragment>
        <ListItem
          style={{paddingLeft: 16}}
          button
          selected={selected}
          onClick={() => onClick()}
        >
          <ListItemAvatar>
            { loading
              ? <Skeleton style={avatarStyle} variant="rect"  />
              : (objects && objects.length > 0)
              ? <Avatar style={avatarStyle} variant="square" src={objects[0].attributes.previewUrl} />
              : (objects && objects.length === 0)
              ? <Avatar style={avatarStyle} variant="square"><LabelsetIcon /></Avatar>
              : <Avatar style={avatarStyle} variant="square" ><ErrorIcon /></Avatar>
            }
          </ListItemAvatar>
          <ListItemText
            primary={
              <Box display="flex" flexDirection="row" alignItems="center">
                <Typography noWrap variant="subtitle1">
                  { item.name }
                  <LabelsetBadge
                    labelset={item}
                    dataset={dataset}
                    style={{marginLeft: 8}}
                  />
                </Typography>
              </Box>
            }
            secondary={
              <Box>
                { item.labelConfiguration.labelDefinitions.map(label => (
                  <LabelDefinitionChip
                    key={label.id}
                    style={{margin: 5, marginLeft: 0 }}
                    labelDefinitions={item.labelConfiguration.labelDefinitions}
                    labelId={label.id}
                    disabled
                    outlined
                  />
                ))}
              </Box>
            }
          />
          <ListItemSecondaryAction>
            { dataset &&
              <LabelsetMenu
                dataset={dataset}
                labelset={item}
                onUpdate={onUpdate}
                onDelete={onDelete}
              />
            }
          </ListItemSecondaryAction>
        </ListItem>
      </React.Fragment>
    );
  }
}