import React from 'react';

import {
  ButtonGroup,
  Button,
  Box,
  Divider,
  IconButton,
  Popover,
  Toolbar,
  Typography
} from '@material-ui/core';

import {
  InfoOutlined as InfoIcon,
} from '@material-ui/icons';

import {
  Alert,
  AlertTitle,
  Pagination
} from '@material-ui/lab';

import {
  DataCollectionObject,
  Labelset,
  LabelsetEntry,
  deepClone,
} from '../api';

import {
  ImageContainer,
  ImageTile
} from '../components';

import {
  toggleEmptyLabels,
  lookupLabelDefinitionColor,
  replaceLabelsetEntries
} from '../utils';

interface Props {
  labelset: Labelset;
  objects: Array<DataCollectionObject>;
  selectedEntries: Array<LabelsetEntry>;
  onUpdate: (updates: Array<LabelsetEntry>) => void;
  onClose: () => void;
}

class State {
  selectedIndex: number = 0;
  updates: LabelsetEntry[];
  infoAnchor: HTMLButtonElement | null = null;

  constructor(props: Props) {
    this.updates = deepClone(props.selectedEntries);
  }
}

export class LabelsetEntryClassifier extends React.Component<Props, State> {

  readonly state = new State(this.props);

  componentDidMount(){
    document.addEventListener("keydown", this.handleKeyDown);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyDown);
  }

  handlePreviousAnnotation() {
    const { selectedIndex, updates } = this.state;
    const newIndex = selectedIndex === 0 ? updates.length - 1 : selectedIndex - 1;
    this.setState({ selectedIndex: newIndex });
  }

  handleNextAnnotation() {
    const { selectedIndex, updates } = this.state;
    const newIndex = selectedIndex === updates.length - 1 ? 0 : selectedIndex + 1;
    this.setState({ selectedIndex: newIndex });
  }

  handleKeyDown = (evt: KeyboardEvent) => {
    const { key } = evt;

    if (key === 'Enter') {
      this.applyChanges();
    }
    else if (key === 'Escape') {
      this.cancelChanges();
    }
    else if (key === 'ArrowLeft') {
      this.handlePreviousAnnotation();
    }
    else if (key === 'ArrowRight') {
      this.handleNextAnnotation();
    }
    else if (key.match(/^([0-9])$/)) {
      this.applyLabelDefinition(parseInt(key) - 1);
    }
  }

  applyLabelDefinition(labelIndex: number) {
    const { labelDefinitions } = this.props.labelset.labelConfiguration;
    const { updates, selectedIndex } = this.state;
    const labelDefinition = labelDefinitions[labelIndex];
    const entry = updates[selectedIndex];
    const selected = !!entry.labels.find(l => l.labelId === labelDefinition.id);

    const updatedEntry = toggleEmptyLabels([ entry ], labelDefinition, selected);

    this.setState({
      updates: replaceLabelsetEntries(updates, updatedEntry)
    })
  }

  applyChanges() {
    const { onUpdate, onClose } = this.props;
    const { updates } = this.state;
    onUpdate(updates);
    onClose();
  }

  cancelChanges() {
    const { onClose } = this.props;
    onClose();
  }

  render () {
    const { labelset, objects } = this.props;
    const { infoAnchor, selectedIndex, updates } = this.state;

    const entry = updates[selectedIndex];
    const object = objects.find(o => o.id === entry.datasetObjectId)!;

    const areaOfInterest = labelset.additionalAttributes?.areaOfInterest;
    const { labelConfiguration} = labelset;

    return (
      <Box display="flex" flexDirection="column" flex={1} style={{overflow: 'hidden'}}>
        <Toolbar variant="dense">
          <Typography variant="h6" style={{width: 200}}>{ object.attributes.originalFilename }</Typography>
          <Box mx="auto" />
          <ButtonGroup>
            { labelConfiguration.labelDefinitions.map((labelDefinition, labelIndex) => {
              const [ color ] = lookupLabelDefinitionColor(labelDefinition, labelIndex);
              const selected = entry.labels.find(l => l.labelId === labelDefinition.id);
              return (
                <Button key={labelDefinition.id}
                  size="small"
                  variant={selected ? 'contained' : 'outlined' }
                  style={{
                    borderColor: (selected ? 'inherit' : color),
                    color: (selected ? '#FFF' : color),
                    background: selected ? color : '#FFF'
                  }}
                  children={labelDefinition.name}
                  onClick={() => {
                    this.applyLabelDefinition(labelIndex);
                  }}
                />
              );
              }
            )}
          </ButtonGroup>
          <Box mx="auto" />
          <Popover
            open={!!infoAnchor}
            anchorEl={infoAnchor}
            onClose={evt => this.setState({ infoAnchor: null })}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <Alert severity="info"
              onClose={evt => this.setState({ infoAnchor: null })}
            >
              <AlertTitle>Keyboard Shortcuts</AlertTitle>
              <b>Left</b>: Previous Image<br/>
              <b>Right</b>: Next Image<br/>
              { labelConfiguration.labelDefinitions.map((label, index) => (
                <React.Fragment key={index}>
                  <b>{index + 1}</b>: Toggle "{label.name}"<br/>
                </React.Fragment>
              )) }
              <b>Escape</b>: Cancel changes<br/>
              <b>Enter</b>: Apply changes
            </Alert>
          </Popover>
          <IconButton
            onClick={evt => this.setState({ infoAnchor: evt.currentTarget})}
          >
            <InfoIcon />
          </IconButton>
        </Toolbar>
        <Divider />
        <Box flex={1} style={{overflowY: 'auto'}}>
          <Box>
            <ImageContainer
              columns={1}
              cropBox={areaOfInterest}
              aspectRatio={object.attributes.aspectRatio}
            >
              <ImageTile
                standalone
                labelConfiguration={labelConfiguration}
                image={object}
                labelsetEntry={entry}
              />
            </ImageContainer>
          </Box>
        </Box>
        <Divider />
        <Toolbar variant="dense">
          <Box mx="auto" />
          <Pagination
            page={selectedIndex+1}
            count={updates.length}
            shape="rounded"
            onChange={(e, page) => {
              this.setState({ selectedIndex: page-1 });
            }}
          />
          <Box mx="auto" />
          <Button
            onClick={() => this.cancelChanges()}>
            Cancel
          </Button>
          <Box mx={1} />
          <Button
            variant="contained"
            color="primary"
            onClick={evt => this.applyChanges() }
          >
            Save
          </Button>
        </Toolbar>
      </Box>
    );
  }
}