import API, {
  TRBLLabel,
  DataCollectionObject,
  LabelsetEntry,
  Label,
  LabelDefinition,
  Labelset,
  LabelConfiguration
} from '../api';

const COLORS_MAPPING: any = {
  red: '#f44336',
  green: '#4caf50',
  orange: '#ef6c00',
  blue: '#03a9f4',
  lime: '#cddc39',
  purple: '#9c27b0',
  amber: '#ffc107',
  cyan: '#00bcd4',
  indigo: '#3f51b5',
  yellow: '#ffd600',
  pink: '#ed4b82',
  grey: '#777777',
  black: '#212121'
};

export const listLabelDefinitionColors = Object.keys(COLORS_MAPPING);

export const lookupLabelDefinitionAndColor = (labelDefinitions: Array<LabelDefinition>, labelId: number): [ LabelDefinition, string, string ] => {
  const index = labelDefinitions.findIndex(labelDefinition => labelDefinition.id === labelId);
  const label = labelDefinitions[index];
  const [ rgb, alias ] = lookupLabelDefinitionColor(label, index);
  return [ label, rgb, alias ];
}

export const lookupLabelDefinitionColor = (label: LabelDefinition, index: number): [ string, string ] => {
  const alias = label.attributes?.color || listLabelDefinitionColors[index % listLabelDefinitionColors.length];
  const rgb = COLORS_MAPPING[alias] || alias;
  return [ rgb, alias ];
}

export function filterLabelsets (labelsets: Labelset[], labelConfiguration: LabelConfiguration) {
  return labelsets.filter(l => {
    return l.labelConfiguration.labelType === labelConfiguration.labelType;
  })
}

const toggleEmptyLabel = (labels: Label[], labelDefinition: LabelDefinition, remove: boolean): Label[] => {
  const exists = labels.find(l => l.labelId === labelDefinition.id);
  if (remove) {
    return labels.filter(l => l.labelId !== labelDefinition.id);
  } else if (!exists) {
    return [
      ...labels,
      { labelId: labelDefinition.id, type: 'EmptyLabel', labelAnnotation: {} }
    ];
  } else {
    return labels;
  }
}

export const toggleEmptyLabels = (labelsetEntries: LabelsetEntry[], labelDefinition: LabelDefinition, remove: boolean): LabelsetEntry[] => {
  return labelsetEntries.map(i => {
    return {
      ...i,
      labels: toggleEmptyLabel(i.labels, labelDefinition, remove)
    };
  });
}

export const replaceLabelsetEntries = (labelsetEntries: LabelsetEntry[], updatedEntries: LabelsetEntry[]): LabelsetEntry[] => {
  return labelsetEntries.map(i => {
    return updatedEntries.find(u => u.datasetObjectId === i.datasetObjectId) || i;
  })
}

export const updateLabels = (labels: Label[], labelDefinition: LabelDefinition) => {
  return labels.map(l => ({...l, labelId: labelDefinition.id }));
}

export const updateLabelsetEntries = (labelsetEntries: LabelsetEntry[], selectedEntry: LabelsetEntry, selectedLabels: Label[], labelDefinition: LabelDefinition): LabelsetEntry[] => {
  return labelsetEntries.map(a => (
    a.datasetObjectId !== selectedEntry.datasetObjectId
      ? a
      : {
        ...selectedEntry,
        labels: selectedEntry.labels.map(l => (
          selectedLabels.indexOf(l) !== -1
            ? {...l, labelId: labelDefinition.id }
            : l
        ))
      }
  ))
}

const cropLabel = (item: Label, cropBox?: TRBLLabel): Label | null => {
  const b = item.labelAnnotation as TRBLLabel;
  const c = cropBox;
  if (!c || !b)
    return item;
  else if (b.right < c.left || b.left > c.right || b.top > c.bottom || b.bottom < c.top ) {
    return null;
  } else {
    return {
      ...item,
      labelAnnotation: {
        left: b.left < c.left ? c.left : b.left,
        top: b.top < c.top ? c.top : b.top,
        right: b.right > c.right ? c.right : b.right,
        bottom: b.bottom > c.bottom ? c.bottom : b.bottom
      }
    };
  }
}

export const cropLabelsetEntries = (labelsetEntries: LabelsetEntry[], cropBox?: TRBLLabel): LabelsetEntry[] => {
  return labelsetEntries.map(a => {
    return {
      ...a,
      labels: a.labels.reduce((r, item) => {
        const cropped = cropLabel(item, cropBox);
        if (cropped)
          r.push(cropped);
        return r;
      }, new Array<Label>())
    }
  })
}

export const intersectBox = (a: TRBLLabel | undefined, b: TRBLLabel | undefined) => {
  return a
      && b
      && !( b.left > a.right || b.right < a.left || b.top > a.bottom || b.bottom < a.top )
}

export const syncLabelsetEntries = (labelsetEntries: LabelsetEntry[], update: LabelsetEntry, additions: Label[], deletions: Label[]) => {
  // NOTE: disable deletion for now because it removes overlapping regions
  return labelsetEntries.map(a => {
    if (a.datasetObjectId === update.datasetObjectId)
      return update;

    return {
      ...a,
      labels: [...a.labels, ...additions]
    }
  })
}

export const loadLabelsetObjectsAndEntriesPage = (datasetVersionId: string, labelsetId: string, pageIndex: number = 0): Promise<[Array<DataCollectionObject>, Array<LabelsetEntry>]> => {
  const take = 100, skip = take * pageIndex, ordering = 'asc';
  return API.datasets.getDatasetVersionObjects(datasetVersionId, { take, skip, ordering })
  .then(objects => {
    const objectsIds = objects.map(o => o.id);
    return API.labelsets.searchLabelsetEntries(labelsetId, objectsIds)
    .then(matches => {
      const entries = objectsIds.map(id => {
        return matches.find(entry => entry.datasetObjectId === id) || {
          datasetObjectId: id,
          labels: []
        }
      })
      return [ objects, entries ];
    })
  });
}