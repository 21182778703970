import React from 'react';

import {
  IconButton,
  Box,
  Slider
} from '@material-ui/core';

import {
  ViewComfy as ZoomOutIcon,
  InsertPhoto as ZoomInIcon
} from '@material-ui/icons';

import {
  TRBLLabel
} from '../api';

interface Props {
  containerRef: React.RefObject<any>;
  cropBox?: TRBLLabel;
  columns?: number;
  onChange: (value: number) => void;
}

class State {
  value: number = 0;
  minimum: number = 0;
  maximum: number = 0;
}

export class ImageZoomControl extends React.Component<Props, State> {

  readonly state = new State();

  handleChange = (value: number) => {
    this.setState({ value });
    this.props.onChange(value);
  }

  computeRange = () => {
    const container = this.props.containerRef.current;
    if (!container)
      return;

    const minimum = 2;
    const maximum = Math.ceil(container.clientWidth / 250);
    const previousValue = this.state.value;
    const value = !previousValue
        ? this.props.columns || maximum
        : Math.max(minimum, Math.min(maximum, previousValue));

    this.setState({
      minimum,
      maximum,
      value
    });

    if (value !== previousValue)
      this.props.onChange(value);
  }

  componentDidMount() {
    this.computeRange();

    window.addEventListener('resize', this.computeRange.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.computeRange.bind(this));
  }

  render() {
    const { value, minimum, maximum } = this.state;

    if (minimum === maximum)
      return null;

    return (
      <Box display="flex" flexDirection="column-reverse" style={{height: 200}}>
        <IconButton
          disabled={value === minimum}
          onClick={evt => this.handleChange(minimum)} size="small"
          children={<ZoomInIcon />}
        />
        <Box m={.5} />
        <Slider style={{flex: 1}}
          orientation="vertical"
          color="secondary"
          value={value}
          min={minimum}
          max={maximum}
          step={1}
          marks={false}
          track={false}
          onChange={(evt, newValue) => this.handleChange(newValue as number) }
        />
        <Box m={.5} />
        <IconButton
          disabled={value === maximum}
          onClick={evt => this.handleChange(maximum)} size="small">
          <ZoomOutIcon />
        </IconButton>
      </Box>
    )
  }
}