import React from 'react';

import {
  Box,
  Divider,
  IconButton,
  Toolbar,
  Typography
} from '@material-ui/core';

import {
  Close as CloseIcon
} from '@material-ui/icons';

import {
  DataCollectionObject,
  LabelsetEntry,
  LabelConfiguration,
  TRBLLabel
} from '../api';

import {
  ImageContainer,
  ImageTile,
  LabelDefinitionChip
} from '../components';

interface Props {
  icon: React.ReactNode;
  object: DataCollectionObject;
  labelsetEntry?: LabelsetEntry;
  labelConfiguration?: LabelConfiguration;
  areaOfInterest?: TRBLLabel;
  onClose: () => void;
}

export class ImagePreview extends React.Component<Props> {


  render() {
    const { icon, object, labelsetEntry, labelConfiguration, areaOfInterest, onClose } = this.props;
    const { labelDefinitions } = labelConfiguration || {};

    return (
      <Box display="flex" flexDirection="column" style={{overflowY: 'hidden'}}>
        <Toolbar variant="dense">
          { icon }
          <Box mx={1} />
          <Typography variant="h6" noWrap>{object.attributes.originalFilename}</Typography>
          <Box mx="auto" />

          { labelsetEntry && labelDefinitions && labelDefinitions
              .filter(ld => labelsetEntry.labels.find(l => l.labelId === ld.id))
              .map((ld) => (
            <LabelDefinitionChip key={ld.id}
              style={{margin: 5, marginLeft: 0 }}
              labelId={ld.id}
              labelDefinitions={labelDefinitions}
            />
          ))}
          <Box mx="auto" />
          <IconButton
            onClick={onClose}
            children={<CloseIcon />}
          />
        </Toolbar>
        <Divider />
        <Box flex={1} style={{overflowY: 'auto'}}>
          <Box>
            <ImageContainer
              columns={1}
              cropBox={areaOfInterest}
              aspectRatio={object.attributes.aspectRatio}
            >
              <ImageTile
                standalone
                labelConfiguration={labelConfiguration}
                image={object}
                labelsetEntry={labelsetEntry}
              />
            </ImageContainer>
          </Box>
        </Box>
      </Box>
    );
  }
}