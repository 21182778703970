import React from 'react';

import {
  withRouter,
  Link as RouterLink,
  Route,
  RouteComponentProps,
  Switch
} from 'react-router-dom';

import {
  IconButton,
} from '@material-ui/core';

import {
  Add as AddIcon,
} from '@material-ui/icons';

import API, {
  Dataset,
  Template
} from '../api';

import {
  DatasetIcon,
  ErrorBox,
  ContentDialog,
  DebugDialog,
  LabelsetSkeleton,
  LabelsetEditor,
  LabelsetViewer,
  LabelsetManager
} from '../components';

interface Props extends RouteComponentProps {
  path: string;
  datasetVersionId?: string;
  templateVersionId?: string;
}

class State {
  loading: boolean = true;
  dataset?: Dataset;
  template?: Template;
  error?: Error
}

export const LabelsetRouter = withRouter(class extends React.Component<Props, State> {

  readonly state = new State();

  componentDidMount() {
    const { datasetVersionId, templateVersionId } = this.props;
    Promise.all([
      datasetVersionId ? API.datasets.getDatasetVersion(datasetVersionId) : undefined,
      templateVersionId ? API.templates.getTemplateVersion(templateVersionId) : undefined
    ])
    .then(([dataset, template]) => this.setState({ loading: false, dataset, template }))
    .catch(error => this.setState({ loading: false, error }))
  }

  reload = () => {
    this.setState(new State());
    this.componentDidMount();
  }

  render() {
    const { path, datasetVersionId } = this.props;
    const { loading, dataset, template, error } = this.state;

    if (loading)
      return <LabelsetSkeleton aspectRatio={1} columns={8} imagesCount={20} />;

    if (error || !dataset)
      return <ErrorBox message={error?.message} onReload={() => this.reload()} />;

    return (
      <Switch>
        <Route path={path} >
          <LabelsetManager
            labelsets={() => API.labelsets.listLabelsets({ datasetVersionId })}
            renderSelection={(labelsets, selection, manager) => (
              <LabelsetViewer
                key={selection}
                labelsetId={selection}
                datasetVersionId={datasetVersionId!}
              />
            )}
            renderActions={(labelsets, manager)  => (
              <React.Fragment>
                { template &&
                  <ContentDialog
                    fullscreen
                    trigger={<IconButton><AddIcon /></IconButton>}
                    renderContent={(close) => (
                      <LabelsetEditor
                        key={path}
                        datasetVersionId={dataset.datasetVersionId}
                        labelConfiguration={template.settings.labelSettings.labelConfiguration}
                        onCreate={ labelset  => {
                          manager.reload(labelset.id);
                        }}
                        onUpdate={ labelset  => {
                          manager.reload(labelset.id);
                        }}
                        onClose={close}
                      />
                    )}
                  />
                }
                <DebugDialog object={labelsets} />
                { dataset &&
                  <IconButton component={RouterLink} to={`/dev/datasets?dataCollectionId=${dataset.dataCollectionId}`}><DatasetIcon /></IconButton>
                }
              </React.Fragment>
            )}
          />
        </Route>
      </Switch>
    );
  }
});