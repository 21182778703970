import React from 'react';

import {
  IconButton,
  Box,
  Toolbar,
  Typography
} from '@material-ui/core';

import {
  Close as CloseIcon,
  Settings as SettingsIcon,
  Edit as EditIcon,
  DeleteForever as DeleteIcon
} from '@material-ui/icons';

import API, {
  DataCollectionObject,
  Dataset,
  Labelset,
  LabelsetEntry,
  LabelsetInput,
  LabelType
} from '../api';

import {
  ContentDialog,
  DebugDialog,
  ErrorBox,
  ImageContainer,
  ImagePreview,
  ImageTile,
  ImageZoomControl,
  LabelDefinitionChip,
  LabelsetBadge,
  LabelsetEditor,
  LabelsetForm,
  LabelsetIcon,
  LabelsetSkeleton,
  PaginationToolbar,
  ResourceDeleteDialog,
  ResourceEditDialog,
} from '../components';

import {
  loadLabelsetObjectsAndEntriesPage
} from '../utils';

interface Props {
  datasetVersionId: string;
  labelsetId: string;
  actions?: React.ReactNode;
  style?: React.CSSProperties;
  onClose?: () => void;
  onUpdate?: (labelset: Labelset) => void;
  onDelete?: (labelset: Labelset) => void;
}

class State {
  loading?: boolean;
  paginating?: boolean;
  error?: Error;
  dataset?: Dataset;
  labelset?: Labelset;
  objects?: Array<DataCollectionObject>;
  entries?: Array<LabelsetEntry>;
  locked: boolean = true;
  pageIndex: number = 0;
  columns: number = 0;
  imagePreview?: DataCollectionObject;
}

export class LabelsetViewer extends React.Component<Props, State> {

  readonly state = new State();

  private containerRef = React.createRef<HTMLDivElement>();

  onPageIndexChange = (datasetVersionId: string, labelsetId: string, pageIndex: number) => {
    this.setState({ paginating: true, error: undefined, pageIndex });
    loadLabelsetObjectsAndEntriesPage(datasetVersionId, labelsetId, pageIndex)
    .then(([ objects, entries]) => {
      this.setState({ paginating: false, objects, entries });
    })
    .catch(error => this.setState({ paginating: false, error }))
  }

  reload = (reset: boolean = false) => {
    const { datasetVersionId, labelsetId } = this.props;
    const { pageIndex } = this.state;

    if (reset)
      this.setState({ loading: true, error: undefined, pageIndex: 0 });

    Promise.all([
      API.datasets.getDatasetVersion(datasetVersionId),
      API.labelsets.getLabelset(labelsetId),
      loadLabelsetObjectsAndEntriesPage(datasetVersionId, labelsetId, pageIndex)
    ])
    .then(([ dataset, labelset, [ objects, entries]]) => {
      const state = {
        loading: false,
        dataset,
        labelset,
        objects,
        entries
      };
      // temporary workaround to find out if object is locked
      API.labelsets.patchLabelsetEntries(labelset.id, [], [])
      .then(dataset => this.setState({ ...state, locked: false }))
      .catch(error => this.setState({ ...state, locked: true }))
    })
    .catch(error => this.setState({ loading: false, error }))
  }

  componentDidMount() {
    this.reload(true);
  }

  render() {
    const { actions, style, onClose, onUpdate, onDelete } = this.props;
    const { loading, paginating, error, columns, dataset, labelset, objects, entries, pageIndex, locked, imagePreview } = this.state;

    if (loading)
      return <LabelsetSkeleton aspectRatio={1} columns={8} imagesCount={20} onClose={onClose} />;

    if (error || !dataset || !labelset || !objects || !entries)
      return <ErrorBox message={error?.message} onReload={() => this.componentDidMount()} />

    const { numberOfObjects } = dataset;
    const { aspectRatio } = objects[0]?.attributes;
    const { areaOfInterest } = labelset.additionalAttributes || {};
    const { labelConfiguration } = labelset;
    const { labelType, labelDefinitions } = labelConfiguration;

    return (
      <Box style={style} flex={1} display="flex" flexDirection="column">
        <Toolbar>
          <LabelsetIcon />
          <Box mx={1} />
          <Typography variant="h6">{labelset.name}</Typography>
          <Box mx={1} />
          <LabelsetBadge
            labelset={labelset}
            dataset={dataset}
          />
          <Box mx={2} />
          <DebugDialog object={labelset} />
          <ResourceEditDialog<LabelsetInput, Labelset>
            title="Basic Settings"
            resource={{...labelset}}
            trigger={<IconButton><SettingsIcon/></IconButton>}
            action={body => API.labelsets.updateLabelset(labelset.id, body) }
            onSuccess={labelset => {
              this.setState({ labelset });
            }}
            renderForm={(labelsetInput, ref, handleSubmit) => (
              <LabelsetForm
                labelsetInput={labelsetInput}
                innerRef={ref}
                onSubmit={handleSubmit} />
            )}
          />
          { !locked &&
            <ContentDialog
              fullscreen
              trigger={<IconButton><EditIcon /></IconButton>}
              renderContent={(close) => (
                <LabelsetEditor
                  datasetVersionId={dataset.datasetVersionId}
                  labelsetId={labelset.id}
                  onUpdate={labelset => {
                    this.setState({ labelset });
                    this.onPageIndexChange(labelset.datasetVersionId, labelset.id, pageIndex);
                    onUpdate?.(labelset);
                  }}
                  onClose={close}
                />
              )}
            />
          }
          { !locked &&
            <ResourceDeleteDialog<Labelset>
              resource={labelset}
              title="Delete Labelset ?"
              trigger={<IconButton><DeleteIcon/></IconButton>}
              action={resource => API.labelsets.deleteLabelset(labelset.id) }
              onSuccess={labelset => {
                onDelete?.(labelset);
                onClose?.();
              }}
            />
          }
          { actions }
          <Box mx="auto" />
          { labelDefinitions && labelType !== LabelType.Classification && labelDefinitions.map((labelDefinition, index, definitions) => (
            <LabelDefinitionChip key={labelDefinition.id}
              style={{margin: 5, marginLeft: 0 }}
              labelId={labelDefinition.id}
              labelDefinitions={definitions}
            />
          ))}
          <Box mx="auto" />
          { onClose &&
            <IconButton
              onClick={() => {
                onUpdate?.(labelset)
                onClose?.();
              }}
            >
              <CloseIcon />
            </IconButton>
          }
        </Toolbar>
        { imagePreview &&
          <ContentDialog
            size="lg"
            renderContent={(close) => (
              <ImagePreview
                icon={<LabelsetIcon/>}
                object={imagePreview}
                labelsetEntry={entries.find(entry => entry.datasetObjectId === imagePreview.id)}
                labelConfiguration={labelConfiguration}
                areaOfInterest={areaOfInterest}
                onClose={() => this.setState({ imagePreview: undefined })}
              />
            )}
          />
        }
        <Box display="flex" flexDirection="row-reverse" style={{flex: '1 1 1px', overflowY: 'auto'}}>
          <Box style={{flex: '1 1 1px', overflowY: 'scroll'}} { ...{ref: this.containerRef}}>
            { numberOfObjects === 0 &&
              <Box display="flex" p={1} justifyContent="center"><Typography variant="body2" color="textSecondary">Dataset is empty.</Typography></Box>
            }
            { paginating &&
              <Box style={{flex: '1 1 1px', overflowY: 'scroll'}}>
                <ImageContainer
                  columns={columns}
                  aspectRatio={aspectRatio}
                >
                  {Array(100).fill(0).map((val, index) => (
                    <ImageTile key={index} />
                  ))}
                </ImageContainer>
              </Box>
            }
            { !paginating &&
              <ImageContainer
                columns={columns}
                cropBox={areaOfInterest}
                aspectRatio={aspectRatio}
              >
                { objects.map((image, index) => (
                  <ImageTile
                    key={index}
                    image={image}
                    labelsetEntry={entries.find(entry => entry.datasetObjectId === image.id)}
                    labelConfiguration={labelConfiguration}
                    onClick={() => this.setState({ imagePreview: image })}
                  />
                ))}
              </ImageContainer>
            }
          </Box>
          <Box paddingLeft={2.5}>
            <ImageZoomControl
              columns={columns}
              containerRef={this.containerRef}
              cropBox={areaOfInterest}
              onChange={columns => {
                this.setState({ columns });
              }}
            />
          </Box>
        </Box>
        <PaginationToolbar
          pageIndex={pageIndex}
          totalItems={numberOfObjects}
          onPageIndexChange={pageIndex => this.onPageIndexChange(labelset.datasetVersionId, labelset.id, pageIndex)}
        />
      </Box>
    )
  }
}