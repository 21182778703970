import React from 'react';

import {
  fabric
} from 'fabric';

import {
  DataCollectionObject,
  LabelsetEntry,
  Label,
  LabelConfiguration,
  TRBLLabel,
  deepEqual
} from '../api';

import {
  lookupLabelDefinitionAndColor
} from '../utils';

interface Dimensions {
  left: number;
  top: number;
  width: number;
  height: number;
}

interface Props {
  labelsetEntry: LabelsetEntry;
  labelConfiguration: LabelConfiguration;
  aspectRatio: number;
  image: DataCollectionObject;
  cropBox: TRBLLabel | undefined;
  cropMode?: boolean;
  canDelete?: boolean;
  style?: React.CSSProperties;
  selection?: Label[];
  onCropChange?: (cropBox?: TRBLLabel) => void
  onAddition?: (annotation: LabelsetEntry, additions: Label[]) => void;
  onDeletion?: (annotation: LabelsetEntry, deletions: Label[]) => void;
  onChange?: (annotation: LabelsetEntry) => void;
  onSelection?: (labels: Label[]) => void;
  onDebug?: (coords: TRBLLabel) => void;
}

class State { }

export class ImageCanvas extends React.Component<Props, State> {

  readonly state = new State();

  private canvasRef = React.createRef<HTMLDivElement>();

  private canvas: fabric.Canvas = new fabric.Canvas(null);

  private img?: fabric.Image;

  private drawRect?: fabric.Rect;
  private drawStart?: number;
  private drawX?: number;
  private drawY?: number;

  componentDidMount() {
    const { image } = this.props;

    const container = this.canvasRef.current;

    if (container) {
      this.canvas = new fabric.Canvas(container.querySelector('canvas'), {
        // renderOnAddRemove: false,
        backgroundColor: '#EFEFEF',
        defaultCursor: 'crosshair'
      });

      this.addDeleteControl();

      // prevent objects to go outside of canvas
      this.canvas.on('before:render', this.dragLimitSelection.bind(this));

      // on mouse down prepare new rectangle shape
      this.canvas.on('mouse:down', this.drawRegion.bind(this));

      // on mouse move update rectangle coordinates
      this.canvas.on('mouse:move', this.resizeRegion.bind(this));

      this.canvas.on('mouse:move', this.debugCoordinates.bind(this));

      (this.canvas as any).on('object:modified', this.updateCoords.bind(this));

      // on mouse up add rectangle to canvas
      this.canvas.on('mouse:up', this.addRegion.bind(this));

      this.canvas.on('selection:created', this.highlightSelection.bind(this));
      this.canvas.on('selection:updated', this.highlightSelection.bind(this));
      this.canvas.on('selection:cleared', this.clearSelection.bind(this));

      window.addEventListener('resize', this.resizeCanvas.bind(this));

      this.resizeCanvas();
      this.redraw();

      this.loadImage(image.attributes.previewUrl);
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (!this.canvas)
      return;

    const { selection, labelsetEntry } = this.props;

    if (!deepEqual(prevProps.labelsetEntry, labelsetEntry)) {
      this.redraw();
    }
    else if (selection !== undefined && !deepEqual(prevProps.selection, selection)) {
      this.applySelection(selection);
    }
  }

  componentWillUnmount() {
    if (this.canvas) {
      window.removeEventListener('resize', this.resizeCanvas.bind(this));
    }
  }

  render() {
    const { style } = this.props;

    return (
      <div ref={this.canvasRef} style={style}>
        <canvas />
      </div>
    );
  }

  computeCrop () {
    const fullsize = { left: 0, top: 0, right: 1, bottom: 1 };
    if (this.props.cropMode)
      return fullsize;
    else
      return this.props.cropBox || fullsize;
  }

  // remove all regions
  clear () {
    const regions = this.getRegions();
    this.canvas.remove(...regions);
    this.canvas.discardActiveObject();
  }

  applyRegionLabel (rect: fabric.Rect, labelId: number) {
    const [ , color ] = lookupLabelDefinitionAndColor(this.props.labelConfiguration.labelDefinitions, labelId);
    rect.set({
      fill: color,
      borderColor: color,
      cornerColor: color,
      stroke: color
    })
    rect.data.labelId = labelId;
  }

  // TODO: move to constructor ?
  loadImage (url: string) {
    fabric.Image.fromURL(url, (img) => {
      this.img = img;

      this.resizeCanvas();
    });
  }

  createRegion (options: Dimensions, label?: Label) {
    const rect = new fabric.Rect({
      originX: 'left',
      originY: 'top',
      objectCaching: false,
      strokeWidth: 2,
      strokeUniform: true,
      opacity: 0.5,
      // borderDashArray: [ 5, 5 ],
      borderScaleFactor: 2,
      hasBorders: true,
      hasControls: true,
      cornerSize: 10,
      cornerStyle: 'circle', // 'rect'
      transparentCorners: true,
      perPixelTargetFind: false,
      ...options
    });

    // store label data
    rect.data = label || {};

    // retrieve label definition and apply color
    if (label) {
      this.applyRegionLabel(rect, label.labelId);
    } else if (this.props.cropMode) {
      rect.set({
        fill: 'transparent',
        borderColor: '#FFF',
        cornerColor: '#FFF',
        stroke: '#FFF'
      })
    }

    // disable rotation control
    rect.setControlsVisibility({ mtr: false });

    // update coordinates on any move
    const setCoords = rect.setCoords.bind(rect);
    (rect as any).on({
      moving: setCoords,
      scaling: setCoords,
      skewing: setCoords,
    });

    return rect;
  }

  updateRegionCoords(region: fabric.Object) {
    console.debug('updateRegionCoords');

    // FIXME: group scale bug on resize
    let dimensions = {
      left: region.left!,
      top: region.top!,
      width: region.width! * (region.scaleX || 1),
      height: region.height! * (region.scaleY || 1)
    }
    const { group } = region;
    if (group) {
      dimensions = {
        left: (dimensions.left + (group.left! + group.width!/2*(region.scaleX || 1))),
        top: (dimensions.top + (group.top! + group.height!/2*(region.scaleY || 1))),
        width: dimensions.width * (group.scaleX || 1),
        height: dimensions.height * (group.scaleY || 1)
      }
    }
    const labelAnnotation = this.dimensionsToCoords(dimensions)
    region.data = {
      ...region.data,
      labelAnnotation: labelAnnotation
    }
  }

  updateCoords () {
    console.debug('updateCoords');

    const activeRegions = this.canvas.getActiveObjects();
    activeRegions.forEach(region => this.updateRegionCoords(region));

    this.notifyChanges([], []);
    this.applySelection(activeRegions.map(a => a.data));
  }

  drawRegion (o: fabric.IEvent) {
    // discard event when collision detecting with other selectable object
    if (!!o.target && o.target.selectable)
      return;

    // discard when in crop mode and one region has already been drawn
    if (this.props.cropMode && this.getRegions().length !== 0)
      return;

    // retrieve pointer
    const pointer = this.canvas.getPointer(o.e);

    // default label definition to first item
    const { labelDefinitions } = this.props.labelConfiguration;
    const defaultLabelDefinition = labelDefinitions[0];

    this.drawStart = +new Date();
    this.drawX = pointer.x;
    this.drawY = pointer.y;
    this.drawRect = this.createRegion({
      left: this.drawX,
      top: this.drawY,
      width: pointer.x - this.drawX,
      height: pointer.y - this.drawY
    }, this.props.cropMode ? undefined : { labelId: defaultLabelDefinition.id, type: 'TRBLLabel', labelAnnotation: { top: 0, right: 0, bottom: 0, left: 0 } });
  }

  resizeRegion (o: fabric.IEvent) {
    if (!this.drawStart || !this.drawRect || !this.drawX || !this.drawY)
      return;

    var pointer = this.canvas.getPointer(o.e);

    if(this.drawX > pointer.x){
      this.drawRect.set({
        left: Math.max(0, pointer.x),
        width: this.drawX - Math.max(0, pointer.x)
      });
    } else if (this.drawX < pointer.x) {
      this.drawRect.set({
        width: Math.min(this.canvas.width!, pointer.x) - this.drawX
      });
    }
    if(this.drawY > pointer.y) {
      this.drawRect.set({
        top: Math.max(0, pointer.y),
        height: this.drawY - Math.max(0, pointer.y)
      });
    } else if (this.drawY < pointer.y) {
      this.drawRect.set({
        height: Math.min(this.canvas.height!, pointer.y) - this.drawY
      });
    }
    this.canvas.requestRenderAll();
  }

  coordsToDimensions (coords: TRBLLabel) {
    const canvas = this.canvas;
    const crop = this.computeCrop();

    const scaleX = 1 / (crop.right - crop.left);
    const scaleY = 1 / (crop.bottom - crop.top);

    const left = (coords.left - crop.left) * scaleX * canvas.width!;
    const top = (coords.top - crop.top) * scaleY * canvas.height!;
    const width = (coords.right - coords.left) * scaleX * canvas.width!;
    const height = (coords.bottom - coords.top) * scaleY * canvas.height!;
    return {
      left,
      top,
      width,
      height
    }
  }

  dimensionsToCoords(obj: Dimensions) {
    const canvas = this.canvas;
    const crop = this.computeCrop();
    const canvasWidth = canvas.width!;
    const canvasHeight = canvas.height!;

    const scaleX = 1 / (crop.right - crop.left);
    const scaleY = 1 / (crop.bottom - crop.top);

    const b = obj;

    const left = (b.left / canvasWidth / scaleX) + crop.left;
    const top = (b.top / canvasHeight / scaleY) + crop.top;
    const right = ((b.left + b.width) / canvasWidth / scaleX) + crop.left;
    const bottom = ((b.top + b.height) / canvasHeight / scaleY) + crop.top;

    return {
      left,
      top,
      right,
      bottom
    }
  }

  // list all existing regions
  getRegions() {
    const objects = this.canvas.getObjects();
    const regions = objects.filter(o => o.selectable);
    return regions;
  }

  updateCursor() {
    if (this.props.cropMode) {
      const cropRect = this.getRegions()[0];
      this.canvas.defaultCursor = cropRect ? 'not-allowed' : 'crosshair';
    } else {
      this.canvas.defaultCursor = 'crosshair';
    }
  }

  notifyChanges(additions: fabric.Rect[], deletions: fabric.Rect[]) {
    const { onChange, onAddition, onDeletion } = this.props;
    console.debug('notifyChanges');
    if (onChange) {
      const regions = this.getRegions();
      const labels = regions.map(obj => {
        const data = obj.data as Label;
        return data;
      })
      const data = {
        ...this.props.labelsetEntry,
        labels
      }
      if (additions.length > 0 && onAddition)
        onAddition(data, additions.map(a => a.data as Label));
      else if (deletions.length > 0 && onDeletion)
        onDeletion(data, deletions.map(a => a.data as Label));
      else
        onChange(data);
    } else if (this.props.cropMode && this.props.onCropChange) {
      const cropRect = this.getRegions()[0];
      if (!cropRect) {
        this.props.onCropChange(undefined);
      } else {
        const cropBox = cropRect.data?.labelAnnotation;
        this.props.onCropChange(cropBox);
      }
    }
    this.updateCursor();
  }

  redraw () {
    const { labelsetEntry, cropBox, selection } = this.props;

    console.debug('redraw');

    // clear canvas
    this.clear();

    // adding regions
    labelsetEntry.labels.forEach(label => {
      const bounds = label.labelAnnotation as TRBLLabel;
      if (bounds) {
        const dimensions = this.coordsToDimensions(bounds);
        const region = this.createRegion(dimensions, label);
        if (this.props.cropMode) {
          region.selectable = false;
          region.evented = false;
        }
        this.canvas.add(region);
      }
    })

    // adding crop box in crop mode
    if (this.props.cropMode && cropBox) {
      const dimensions = this.coordsToDimensions(cropBox);
      const cropRegion = this.createRegion(dimensions)
      this.canvas.add(cropRegion);
      this.canvas.setActiveObject(cropRegion);
    }

    this.updateCursor();

    if (selection) {
      this.applySelection(selection);
    }

    this.canvas.requestRenderAll();
  }

  debugCoordinates (o: fabric.IEvent) {
    if (!this.props.onDebug)
      return;

    var pointer = this.canvas.getPointer(o.e);
    const coords = this.dimensionsToCoords({ left: pointer.x, top: pointer.y, width: 1, height: 1 });
    this.props.onDebug(coords);
  }

  addRegion (o: fabric.IEvent) {
    if (this.drawStart && this.drawRect) {

      // check elasped time since drawing start
      const elapsedTime = +new Date() - this.drawStart;

      // discard quick and small drawings
      if (this.drawRect.width! > 10 && this.drawRect.height! > 10 && elapsedTime > 200) {

        console.debug('addRegion');

        // add object to canvas and automatically select it
        this.updateRegionCoords(this.drawRect);
        this.canvas.add(this.drawRect);

        this.notifyChanges([this.drawRect], []);
        this.applySelection([this.drawRect.data])
      }
    }

    // reset drawing variables
    this.drawStart = undefined;
    this.drawRect = undefined;
    this.drawX = undefined;
    this.drawY = undefined;
  }

  applySelection (selection: Label[]) {
    console.debug('applySelection');
    const regions = this.getRegions();
    const activeRegions = regions.reduce((r, region) => {
      // skip region if already found active to prevent
      // selecting multiple regions with identical coordinates
      if (r.find(ar => deepEqual(ar.data, region.data)))
        return r;

      // add region if matches selection
      if (selection.find(sr => deepEqual(sr, region.data)))
        r.push(region);

      return r;
    }, new Array<fabric.Object>());

    this.canvas.discardActiveObject(undefined);
    if (activeRegions.length == 1 ) {
      this.canvas.setActiveObject(activeRegions[0]);
    } else if (activeRegions.length > 1) {
      const activeGroup = new fabric.ActiveSelection(activeRegions, {
        canvas: this.canvas,
        originX: 'left',
        originY: 'top',
      });
      activeGroup.setControlsVisibility({ mtr: false });
      this.canvas.setActiveObject(activeGroup);
    }
    this.canvas.requestRenderAll();
  }

  getSelection () {
    const selection = this.canvas.getActiveObject();
    return selection;
  }

  notifySelection () {
    const { onSelection } = this.props;
    if (!onSelection)
      return;

    console.debug('notifySelection');

    const activeRegions = this.canvas.getActiveObjects();
    const selection = activeRegions.map(region => region.data as Label);
    onSelection(selection);
  }

  highlightSelection (o: fabric.IEvent) {
    const regions = this.getRegions();
    regions.forEach(region => region.set({ opacity: 0.5, strokeWidth: 2 }));

    const activeRegions = this.canvas.getActiveObjects();
    activeRegions.forEach(region => region.set({ opacity: 0, strokeWidth: 4}));

    this.notifySelection();

    // prevent default event so that delete control is not triggered automatically
    if (o.e)
      o.e.preventDefault();

    return true;
  }

  clearSelection () {
    // const { cropMode } = this.options;
    const regions = this.getRegions();

    regions.forEach(region => {
      region.set({ opacity: 0.5, strokeWidth: 2 })
    });

    this.notifySelection();
  }

  dragLimitSelection () {
    const obj = this.getSelection();
    if (obj) {
      const b = obj.getBoundingRect();
      const c = this.canvas;

      // compute left and top based on canvas dimensions
      const adjustedLeft = Math.max(0,  Math.min(b.left, c.width! - b.width));
      const adjustedTop = Math.max(0, Math.min(b.top, c.height! - b.height));

      obj.set({
        left: obj.left! - b.left + adjustedLeft,
        top: obj.top! - b.top + adjustedTop
      })
      obj.setCoords();
    }
  }

  /* CONTROLS */

  addGrid() {
    for (let i=0.1; i<1; i+=0.1) {
      const dimensions = this.coordsToDimensions({ left: i, top: i, right: i, bottom: i })
      const { left, top } = dimensions;
      const gridStyle = { stroke: "FFF", strokeWidth: 0.5, selectable:false, strokeDashArray: [1, 1]};
      this.canvas.add(new fabric.Line([left, 0, left, this.canvas.height!], gridStyle));
      this.canvas.add(new fabric.Line([0, top, this.canvas.width!, top], gridStyle));
    }
  }

  addDeleteControl() {
    var icon = document.createElement('img');
    icon.src = "data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3Csvg version='1.1' id='Ebene_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='595.275px' height='595.275px' viewBox='200 215 230 470' xml:space='preserve'%3E%3Ccircle style='fill:%23F44336;' cx='299.76' cy='439.067' r='218.516'/%3E%3Cg%3E%3Crect x='267.162' y='307.978' transform='matrix(0.7071 -0.7071 0.7071 0.7071 -222.6202 340.6915)' style='fill:white;' width='65.545' height='262.18'/%3E%3Crect x='266.988' y='308.153' transform='matrix(0.7071 0.7071 -0.7071 0.7071 398.3889 -83.3116)' style='fill:white;' width='65.544' height='262.179'/%3E%3C/g%3E%3C/svg%3E";

    fabric.Object.prototype.controls.deleteControl = new fabric.Control({
      x: 0.5,
      y: -0.5,
      offsetY: 10,
      offsetX: -10,
      cursorStyle: 'pointer',
      mouseDownHandler: (eventData, transform) => {
        if (eventData.defaultPrevented)
          return true;

        var activeObjects = this.canvas.getActiveObjects();
        if (activeObjects) {
          this.canvas.discardActiveObject();
          this.canvas.remove(...activeObjects);
          this.notifyChanges([], activeObjects);
        }
        return true;
      },
      render: (ctx, left, top, styleOverride, fabricObject) => {
        var size = 18;
        ctx.save();
        ctx.translate(left, top);
        ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle!));
        ctx.drawImage(icon, -size/2, -size/2, size, size);
        ctx.restore();
      },
    });
  }

  /* RESIZING */

  resizeObject (obj: fabric.Object, ratioX: number, ratioY: number) {
    obj.set({
      scaleX: obj.scaleX! * ratioX,
      scaleY: obj.scaleY! * ratioY,
      left: obj.left! * ratioX,
      top: obj.top! * ratioY
    })
    obj.setCoords();
  }

  resizeCanvas () {
    if (!this.canvasRef.current)
      return;

    const crop = this.computeCrop();

    // retrieve parent container available width/height and compute aspect ratio
    const { clientWidth, clientHeight } = this.canvasRef.current!;
    const clientRatio = clientWidth / clientHeight;

    // compute image and new aspect ratio based
    const originalRatio = this.props.aspectRatio; //img.width! / img.height!;
    const aspectRatio = originalRatio * (crop.right - crop.left) / (crop.bottom - crop.top);

    // compute new canvas width and height
    const canvasWidth = clientRatio < aspectRatio ? clientWidth : clientHeight * aspectRatio;
    const canvasHeight = clientRatio < aspectRatio ? clientWidth / aspectRatio : clientHeight;

    // compute canvas dimensions change against previous values
    const canvasWidthChange = canvasWidth / this.canvas.width!;
    const canvasHeightChange = canvasHeight / this.canvas.height!;

    // apply new canvas dimensions
    this.canvas.setDimensions({ width: canvasWidth, height: canvasHeight });

    const img = this.img;
    if (img) {
      // compute new image scales
      const imgScaleX = 1 / (crop.right - crop.left) * (canvasWidth / img.width!);
      const imgScaleY = 1 / (crop.bottom - crop.top) * (canvasHeight / img.height!);

      // apply background image
      this.canvas.setBackgroundImage(img, this.canvas.renderAll.bind(this.canvas), {
        originX: 'left',
        originY: 'top',
        scaleX: imgScaleX,
        scaleY: imgScaleY,
        left: -crop.left * imgScaleX * img.width!,
        top: -crop.top * imgScaleY * img.height!,
        // opacity: 0.5
      });
    }

    // resize active selection first
    // TODO: not working
    const active = this.canvas.getActiveObject();
    if (active && active.hasOwnProperty('_objects')) {
      this.resizeObject(active, canvasWidthChange, canvasHeightChange);
    }

    // resize existing objects based on canvas scale change
    this.canvas.forEachObject((obj) => {
      // if (!obj.group)
        this.resizeObject(obj, canvasWidthChange, canvasHeightChange)
    });

    // redraw
    this.canvas.requestRenderAll();
  }
}