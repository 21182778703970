export type LabelDefinitionAttributes = {
  color: string;
}

export type LabelDefinition = {
  id: number;
  name: string;
  attributes?: LabelDefinitionAttributes;
}

export enum LabelType {
  BoundingBoxes = 'boundingBoxes',
  // Segmentation = 'segmentation',
  Classification = 'classification'
}

export type LabelConfiguration = {
  labelType: LabelType;
  labelDefinitions: Array<LabelDefinition>;
}