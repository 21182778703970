import React from 'react';

import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Toolbar,
  Typography
} from '@material-ui/core';

import {
  Pagination,
} from '@material-ui/lab';

import {
  Crop as CropIcon
} from '@material-ui/icons';

import API, {
  DataCollectionObject,
  Labelset,
  LabelsetEntry,
  TRBLLabel,
  deepClone,
  deepEqual
} from '../api';

import {
  ErrorAlert,
  ImageCanvas
} from '../components';

interface Props {
  labelset: Labelset;
  objects: Array<DataCollectionObject>;
  entries: Array<LabelsetEntry>;
  onUpdate: (labelset: Labelset) => void;
  onClose: () => void;
}

class State {
  areaOfInterest?: TRBLLabel;
  previewIndex: number = 0;
  saving: boolean = false;
  error?: Error;

  constructor(props: Props) {
    this.areaOfInterest = deepClone(props.labelset.additionalAttributes?.areaOfInterest);
  }
}

export class LabelsetCropForm extends React.Component<Props, State> {

  readonly state = new State(this.props);

  componentDidMount(){
    document.addEventListener("keydown", this.handleKeyDown);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyDown);
  }

  handleKeyDown = (evt: KeyboardEvent) => {
    const { key } = evt;
    if (key === 'ArrowLeft') {
      this.handlePreviousAnnotation();
    } else if (key === 'ArrowRight') {
      this.handleNextAnnotation();
    } else if (key === 'Enter') {
      this.handleSave();
    } else if (key === 'Escape') {
      this.handleCancel();
    }
  }

  handlePreviousAnnotation() {
    const { objects } = this.props;
    const { previewIndex } = this.state;
    const nextIndex = previewIndex === 0 ? objects.length - 1 : previewIndex - 1;
    this.setState({ previewIndex: nextIndex})
  }

  handleNextAnnotation() {
    const { objects } = this.props;
    const { previewIndex } = this.state;
    const nextIndex = previewIndex === objects.length - 1 ? 0 : previewIndex + 1;
    this.setState({ previewIndex: nextIndex})
  }

  handleCancel() {
    const { onClose } = this.props;
    onClose();
  }

  handleSave() {
    const { labelset, onUpdate, onClose } = this.props;
    const { areaOfInterest } = this.state;
    this.setState({ saving: true, error: undefined });
    API.labelsets.updateLabelset(labelset.id, {...labelset, additionalAttributes: { areaOfInterest }})
    .then(labelset => {
      this.setState({ saving: false });
      onUpdate(labelset);
      onClose();
    })
    .catch(error => this.setState({ saving: false, error }));
  }

  render() {
    const { labelset, objects, entries } = this.props;
    const { areaOfInterest, previewIndex, saving, error } = this.state;

    const object = objects[previewIndex];
    const labelsetEntry = entries.find(entry => entry.datasetObjectId === object.id);

    return (
      <React.Fragment>
        <Toolbar variant="dense">
          <CropIcon />
          <Box mx={1} />
          <Typography variant="h6" noWrap>{object.attributes.originalFilename}</Typography>
        </Toolbar>
        <Divider />
        { error && <ErrorAlert error={error} /> }
        <Box display="flex" flex={1} flexDirection="row">
          <ImageCanvas
            key={object.id}
            labelConfiguration={labelset.labelConfiguration}
            image={object}
            labelsetEntry={labelsetEntry!}
            aspectRatio={object.attributes.aspectRatio}
            cropBox={areaOfInterest}
            cropMode
            onCropChange={areaOfInterest => this.setState({ areaOfInterest })}
            style={{flex: 1, justifyContent: 'center', display: 'flex'}}
          />
        </Box>
        <Toolbar variant="dense">
          <Box mx="auto" />
          <Pagination
            page={previewIndex+1}
            count={objects.length}
            shape="rounded"
            onChange={(e, page) => {
              this.setState({ previewIndex: page-1 })
            }}
          />
          <Box mx="auto" />
          <Button
            onClick={() => this.handleCancel()}
            children="Cancel"
          />
          <Button
            startIcon={ saving && <CircularProgress color="inherit" size={20} /> }
            variant="contained"
            color="primary"
            disabled={deepEqual(labelset.additionalAttributes?.areaOfInterest, areaOfInterest)}
            onClick={evt => this.handleSave()}
            children="Save"
          />
        </Toolbar>
      </React.Fragment>
    );
  }
}