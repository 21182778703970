import React from 'react';

import {
  Box,
  Button,
  Menu,
  MenuItem,
  Toolbar,
  Typography
} from '@material-ui/core';

import {
  Add as AddIcon
} from '@material-ui/icons';

import API, {
  Dataset,
  Labelset,
  LabelConfiguration,
  LabelType
} from '../api';

import {
  LabelsetIcon,
  LabelsetList,
  LabelsetListSkeleton,
  ErrorBox,
  ContentDialog,
  LabelsetEditor,
  SearchField
} from '../components';

import {
  searchCase
} from '../utils';

interface Props {
  datasetVersionId: string;
  labelConfiguration?: LabelConfiguration;
  selection?: Labelset;
  onSelection?: (labelset?: Labelset) => void;
  style?: React.CSSProperties;
}

class State {
  loading: boolean = true;
  error?: Error;
  dataset?: Dataset;
  labelsets?: Labelset[];
  selection?: Labelset;
  searchText: string = '';
  menuAnchor: HTMLButtonElement | null = null;

  constructor(props: Props) {
    this.selection = props.selection;
  }
}

export class LabelsetPicker extends React.Component<Props, State> {

  readonly state = new State(this.props);

  componentDidMount () {
    this.setState({ loading: true, error: undefined, labelsets: undefined });
    const { datasetVersionId } = this.props;
    Promise.all([
      API.datasets.getDatasetVersion(datasetVersionId),
      API.labelsets.listLabelsets({ datasetVersionId })
    ])
    .then(([dataset, labelsets]) => this.setState({ dataset, labelsets, loading: false }))
    .catch(error => this.setState({ error, loading: false }))
  }

  toggleSelection = (meta: Labelset) => {
    const selection = meta.id !== this.state.selection?.id ? meta : undefined;
    this.setState({ selection });
    this.props.onSelection?.(selection);
  }

  handleUpdate = (labelsets: Labelset[], labelset: Labelset) => {
    this.setState({
      labelsets: labelsets.map(l => l.id === labelset.id ? labelset : l )
    });
  }

  handleDelete = (labelsets: Labelset[], labelset: Labelset) => {
    const { selection } = this.state;
    const updates = labelsets.filter(l => l.id !== labelset.id);
    if (selection && selection.id === labelset.id) {
      this.setState({
        labelsets: updates,
        selection: undefined
      });
      this.props.onSelection?.(undefined);
    }
    else {
      this.setState({
        labelsets: updates
      });
    }
  }

  render() {
    const { style, labelConfiguration } = this.props;
    const { dataset, labelsets, selection, loading, error, searchText, menuAnchor } = this.state;

    const labelDefinitions = [ { id: 0, name: 'A'} , { id: 1, name: 'B' }, { id: 2, name: 'C' } ];

    const filteredLabelsets = (dataset && labelsets) ? labelsets
      .filter(l => !labelConfiguration || l.labelConfiguration.labelType === labelConfiguration.labelType)
      .filter(d => !searchText || searchCase(d.name).indexOf(searchCase(searchText)) !== -1)
      .sort((a, b) => a.name.localeCompare(b.name)) : [];

    return (
      <Box display="flex" flexDirection="column" style={style}>
        <Toolbar variant="dense">
          <LabelsetIcon />
          <Box mx={1} />
          <Typography variant="subtitle1">Pick a Labelset</Typography>
          <Box mx={2} />
          { labelsets && labelsets.length > 0 &&
            <SearchField
              autoFocus
              value={searchText}
              onChange={searchText => this.setState({ searchText })}
            />
          }
          <Box mx="auto" />
          { labelsets && dataset && labelConfiguration &&
            <ContentDialog
              fullscreen
              trigger={<Button color="primary" size="small" startIcon={<AddIcon />}>New</Button>}
              renderContent={(close) => (
                <LabelsetEditor
                  datasetVersionId={dataset.datasetVersionId}
                  labelConfiguration={labelConfiguration}
                  onCreate={labelset => {
                    this.setState({
                      labelsets: [...labelsets, labelset]
                    });
                  }}
                  onUpdate={labelset => {
                    this.setState({
                      labelsets: labelsets.map(l => l.id === labelset.id ? labelset : l)
                    });
                  }}
                  onClose={close}
                />
              )}
            />
          }
          { labelsets && dataset && !labelConfiguration &&
            <div>
              <Button
                color="primary"
                size="small"
                startIcon={<AddIcon />}
                onClick={evt => this.setState({ menuAnchor: evt.currentTarget})}
                children="New" />
              <Menu
                anchorEl={menuAnchor}
                keepMounted
                open={Boolean(menuAnchor)}
                onClose={evt => this.setState({ menuAnchor: null })}
              >
                <ContentDialog
                  fullscreen
                  trigger={<MenuItem onClick={() => this.setState({ menuAnchor: null})}>Classification</MenuItem>}
                  renderContent={(close) => (
                    <LabelsetEditor
                      datasetVersionId={dataset.datasetVersionId}
                      labelConfiguration={{labelType: LabelType.Classification, labelDefinitions }}
                      onCreate={labelset => {
                        this.setState({
                          labelsets: [...labelsets, labelset]
                        });
                      }}
                      onUpdate={labelset => {
                        this.setState({
                          labelsets: labelsets.map(l => l.id === labelset.id ? labelset : l)
                        });
                      }}
                      onClose={close}
                    />
                  )}
                />
                <ContentDialog
                  fullscreen
                  trigger={<MenuItem onClick={() => this.setState({ menuAnchor: null})}>Bounding Boxes</MenuItem>}
                  renderContent={(close) => (
                    <LabelsetEditor
                      datasetVersionId={dataset.datasetVersionId}
                      labelConfiguration={{labelType: LabelType.BoundingBoxes, labelDefinitions }}
                      onCreate={labelset => {
                        this.setState({
                          labelsets: [...labelsets, labelset]
                        });
                      }}
                      onUpdate={labelset => {
                        this.setState({
                          labelsets: labelsets.map(l => l.id === labelset.id ? labelset : l)
                        });
                      }}
                      onClose={close}
                    />
                  )}
                />
              </Menu>
            </div>
          }
        </Toolbar>
        <Box style={{flex: '1 1 1px', overflowY: 'scroll'}}>
          { loading && <LabelsetListSkeleton countItems={10} /> }
          { error && <ErrorBox message={error?.message} onReload={() => this.componentDidMount()} /> }
          { labelsets && filteredLabelsets.length > 0 && dataset &&
            <LabelsetList
              items={filteredLabelsets}
              selection={selection}
              onSelection={this.toggleSelection}
              onUpdate={labelset => this.handleUpdate(labelsets, labelset) }
              onDelete={labelset => this.handleDelete(labelsets, labelset) }
              dataset={dataset}
            />
          }
          { labelsets && labelsets.length === 0 &&
            <Box p={2}>
              <Typography color="textSecondary" variant="body2">No Labelsets available.</Typography>
            </Box>
          }
        </Box>
      </Box>
    );
  }
}