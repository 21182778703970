import React from 'react';

import {
  DataCollection
} from '../api';

import {
  Chip
} from '@material-ui/core';

import {
  CropOriginal as ImagesIcon,
} from '@material-ui/icons';

export type DataCollectionBadgeProps = {
  dataCollection: DataCollection;
  style?: React.CSSProperties;
}

export const DataCollectionBadge = ({
  style,
  dataCollection
}: DataCollectionBadgeProps) => {

  const { numberOfObjects } = dataCollection;

  if (numberOfObjects === undefined)
    return null;

  const styles = {
    color: numberOfObjects === 0 ? 'red' : undefined,
    ...style
  }

  return (
    <Chip
      size="small"
      icon={<ImagesIcon fontSize="small" style={{color: styles.color}} />}
      disabled
      style={styles}
      label={numberOfObjects}
    />
  );
}