import React from 'react';

import {
  List,
  ListItem,
  ListItemText,
  Typography
} from '@material-ui/core';

import {
  Skeleton
} from '@material-ui/lab';

import {
  isString,
  isEmpty
} from 'lodash';

interface SkeletonProps {
  countItems: number;
  secondary?: boolean;
  style?: React.CSSProperties;
}

export class PropertyListSkeleton extends React.Component<SkeletonProps> {
  render() {
    const { countItems, secondary, style } = this.props;
    return (
      <List dense disablePadding style={style}>
        { Array(countItems).fill(0).map((val, index) => (
          <ListItem key={index}>
            <ListItemText
              primary={<Skeleton height={15} width="40%" />}
              secondary={secondary && <Skeleton height={15} width="60%" />}
            />
            <Skeleton height={15} width="25%" />
          </ListItem>
        )) }
      </List>
    );
  }
}

interface ItemProps {
  name: string;
  description?: string;
  children: string | React.ReactNode;
}

export class PropertyListItem extends React.Component<ItemProps> {

  render() {
    const { name, description, children } = this.props;

    if (isEmpty(children))
      return null;

    return (
      <ListItem key={name}>
        <ListItemText
          primary={<Typography noWrap variant="body2">{name}</Typography>}
          secondary={<Typography noWrap variant="caption" color="textSecondary">{description}</Typography>}
        />
        { isString(children)
          ? <Typography noWrap variant="body2">{children}</Typography>
          : children
        }
      </ListItem>
    );
  }
}

interface Props {
  children: React.ReactNode;
  style?: React.CSSProperties;
}

export class PropertyList extends React.Component<Props> {

  render() {
    const { children, style } = this.props;

    return (
      <List dense disablePadding style={style}>
        { children }
      </List>
    );
  }
}