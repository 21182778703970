import React from 'react';

import {
  SelectableGroup
} from 'react-selectable-fast';

import {
  IconButton,
  ButtonGroup,
  Button,
  Box,
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  Toolbar,
  Tooltip,
  Typography,
} from '@material-ui/core';

import {
  Crop as CropIcon,
  Close as CloseIcon,
  HighlightOff as ClearIcon,
  Settings as SettingsIcon,
  ZoomOutMap as LabelIcon,
  ZoomOutMap as ClassifyIcon,
} from '@material-ui/icons';

import {
  Skeleton
} from '@material-ui/lab';

import API, {
  DataCollectionObject,
  DataType,
  Dataset,
  LabelConfiguration,
  Labelset,
  LabelsetEntry,
  LabelsetInput,
  LabelType,
  Template,
  TRBLLabel,
} from '../api';

import {
  ContentDialog,
  DebugDialog,
  ErrorBox,
  ImageContainer,
  ImageZoomControl,
  ImageTile,
  LabelDefinitionChip,
  LabelDefinitionsDialog,
  LabelsetBadge,
  LabelsetIcon,
  LabelsetEntryAnnotator,
  LabelsetEntryClassifier,
  LabelsetForm,
  LabelsetCropForm,
  LabelsetSkeleton,
  PaginationToolbar,
  ResourceEditDialog
} from '../components';

import {
  loadLabelsetObjectsAndEntriesPage,
  lookupLabelDefinitionColor,
  replaceLabelsetEntries,
  toggleEmptyLabels
} from '../utils';

interface Props {
  datasetVersionId: string;
  labelsetId?: string;
  labelConfiguration?: LabelConfiguration;
  template?: Template;
  style?: React.CSSProperties;
  onClose: () => void;
  onCreate?: (labelset: Labelset) => void;
  onUpdate?: (labelset: Labelset) => void;
}

class State {
  loading?: boolean;
  paginating: boolean = false;
  saving: boolean = false;
  error?: Error;
  dataset?: Dataset;
  labelset?: Labelset;
  objects?: Array<DataCollectionObject>;
  entries?: Array<LabelsetEntry>;
  selectedIds: Array<string> = [];
  pageIndex: number = 0;
  columns: number = 0;
  cropBox?: TRBLLabel;
}

export class LabelsetEditor extends React.Component<Props, State> {

  readonly state = new State();

  private selectableRef = React.createRef<SelectableGroup>();

  private containerRef = React.createRef<HTMLDivElement>();

  onPageIndexChange = (datasetVersionId: string, labelsetId: string, pageIndex: number) => {
    this.setState({ paginating: true, error: undefined, pageIndex, selectedIds: [] });
    loadLabelsetObjectsAndEntriesPage(datasetVersionId, labelsetId, pageIndex)
    .then(([ objects, entries]) => {
      this.setState({ paginating: false, objects, entries });
    })
    .catch(error => this.setState({ paginating: false, error }))
  }

  reload = (reset: boolean = false) => {
    const { datasetVersionId, labelsetId, labelConfiguration, onCreate } = this.props;
    const { pageIndex } = this.state;

    if (reset)
      this.setState({ loading: true, error: undefined, pageIndex: 0 });

    (labelsetId
      ? API.labelsets.getLabelset(labelsetId)
      : API.labelsets.createLabelset(datasetVersionId, {
        name: 'New Labelset',
        description: '',
        dataType: DataType.Images,
        labelConfiguration: labelConfiguration!
      })
    )
    .then(labelset => {
      if (!labelsetId)
        onCreate?.(labelset);

      return Promise.all([
        API.datasets.getDatasetVersion(datasetVersionId),
        loadLabelsetObjectsAndEntriesPage(datasetVersionId, labelset.id, pageIndex)
      ])
      .then(([ dataset, [ objects, entries]]) => {
        this.setState({
          loading: false,
          dataset,
          labelset,
          objects,
          entries
        });
      })
    })
    .catch(error => this.setState({ loading: false, error }))
  }

  componentDidMount(){
    this.reload(true);
  }

  patchLabelsetEntries = (labelsetId: string, entries: LabelsetEntry[], updates: LabelsetEntry[]) => {
    const entriesUpdate = replaceLabelsetEntries(entries, updates);
    const includeEntries = entriesUpdate.filter(e => e.labels.length > 0);
    const excludeIds = entriesUpdate.filter(e => e.labels.length === 0).map(e => e.datasetObjectId);
    this.setState({ saving: true, error: undefined });
    API.labelsets.patchLabelsetEntries(labelsetId, includeEntries, excludeIds)
    .then(labelset => {
      this.setState({ saving: false, labelset, entries: entriesUpdate });
      this.props.onUpdate?.(labelset);
    })
    .catch(error => this.setState({ saving: false, error }))
  }

  clearAllLabelsetEntries = (labelsetId: string, objects: Array<DataCollectionObject>) => {
    this.setState({ saving: true, error: undefined });
    API.labelsets.patchLabelsetEntries(labelsetId, [], 'all')
    .then(labelset => {
      this.setState({
        saving: false,
        labelset,
        entries: objects.map(o => ({ datasetObjectId: o.id, labels: [] }))
      });
      this.props.onUpdate?.(labelset);
    })
    .catch(error => this.setState({ saving: false, error }))
  }

  render() {
    const { style, onClose, onUpdate } = this.props;
    const { loading, paginating, saving, error, columns, dataset, labelset, objects, entries, selectedIds, pageIndex } = this.state;

    if (loading)
      return <LabelsetSkeleton aspectRatio={1} columns={8} imagesCount={20} onClose={onClose} />;

    if (error || !dataset || !labelset || !objects || !entries)
      return <ErrorBox message={error?.message} onReload={() => this.componentDidMount()} />

    const { numberOfObjects } = dataset;
    const { aspectRatio } = objects[0]?.attributes || {};
    const { areaOfInterest } = labelset.additionalAttributes || {};
    const { labelConfiguration } = labelset;
    const { labelType, labelDefinitions } = labelConfiguration;
    const selectedEntries = entries.filter(e => selectedIds.includes(e.datasetObjectId));
    const hasMultiplePages = objects.length < numberOfObjects;

    return (
      <Box style={style} flex={1} display="flex" flexDirection="column">
        <Toolbar>
          <LabelsetIcon />
          <Box mx={1} />
          <Typography variant="h6">{labelset.name}</Typography>
          <Box mx={1} />
          <LabelsetBadge
            labelset={labelset}
            dataset={dataset}
          />
          <DebugDialog object={this.state} />
          <ResourceEditDialog<LabelsetInput, Labelset>
            title="Basic Settings"
            resource={{...labelset}}
            trigger={
              <Tooltip title="Basic Settings">
                <IconButton>
                  <SettingsIcon/>
                </IconButton>
              </Tooltip>
            }
            action={body => API.labelsets.updateLabelset(labelset.id, body) }
            onSuccess={labelset => {
              this.setState({ labelset });
              onUpdate?.(labelset)
            }}
            renderForm={(labelsetInput, ref, handleSubmit) => (
              <LabelsetForm
                labelsetInput={labelsetInput}
                innerRef={ref}
                onSubmit={handleSubmit} />
            )}
          />
          <ContentDialog
            fullscreen
            trigger={
              <Tooltip title="Crop Labelset">
                <IconButton><CropIcon /></IconButton>
              </Tooltip>
            }
            renderContent={(close) => (
              <LabelsetCropForm
                labelset={labelset}
                objects={objects}
                entries={entries}
                onUpdate={labelset => {
                  this.setState({ labelset })
                  onUpdate?.(labelset)
                }}
                onClose={close}
              />
            )}
          />
          { hasMultiplePages &&
            <Tooltip title="Clear Labelset">
              <IconButton onClick={() => this.clearAllLabelsetEntries(labelset.id, objects)}>
                <ClearIcon/>
              </IconButton>
            </Tooltip>
          }
          <Box mx="auto" />
          { saving && <CircularProgress size={30} /> }
          { !saving &&
            <IconButton
              onClick={() => {
                onClose();
              }}
            >
              <CloseIcon />
            </IconButton>
          }
        </Toolbar>
        <Divider />
        { paginating &&
          <Toolbar variant="dense">
            <Skeleton width={150} height={20} />
            <Box mx="auto" />
            <Skeleton width={150} height={20} />
          </Toolbar>
        }
        { !paginating &&
          <Toolbar variant="dense">
            <FormControlLabel
              control={
                <Checkbox
                  style={{color: selectedEntries.length > 0 ? 'red' : 'initial'}}
                  checked={selectedEntries.length === objects.length}
                  indeterminate={selectedEntries.length > 0 && selectedEntries.length < objects.length}
                  onChange={evt => {
                    const group = this.selectableRef.current!;
                    if (selectedEntries.length === 0) group.selectAll();
                    else group.clearSelection();
                  }}
                />
              }
              label={
                <Typography variant="subtitle1" noWrap>{selectedEntries.length} / {objects.length} selected { hasMultiplePages && 'in current page'}</Typography>
              }
            />
            <Box mx={1} />
            { labelType === LabelType.Classification &&
              <ContentDialog
                fullscreen
                trigger={
                  <Button
                    color="primary"
                    disabled={selectedEntries.length === 0 || labelDefinitions.length === 0}
                    startIcon={<ClassifyIcon />}
                    children="Annotate"
                  />
                }
                renderContent={close => (
                  <LabelsetEntryClassifier
                    labelset={labelset}
                    objects={objects}
                    selectedEntries={selectedEntries}
                    onUpdate={updates => {
                      this.patchLabelsetEntries(labelset.id, entries, updates);
                    }}
                    onClose={close}
                  />
                )}
              />
            }
            { labelType === LabelType.BoundingBoxes &&
              <ContentDialog
                fullscreen
                trigger={
                  <Button
                    color="primary"
                    disabled={selectedEntries.length === 0 || labelDefinitions.length === 0}
                    startIcon={<LabelIcon />}
                    children="Annotate"
                  />
                }
                renderContent={close => (
                  <LabelsetEntryAnnotator
                    labelset={labelset}
                    objects={objects}
                    selectedEntries={selectedEntries}
                    onUpdate={updates => {
                      this.patchLabelsetEntries(labelset.id, entries, updates);
                    }}
                    onClose={close}
                  />
                )}
              />
            }
            <Button
              color="primary"
              disabled={selectedEntries.length === 0 || selectedEntries.filter(a => a.labels.length > 0).length === 0}
              startIcon={<ClearIcon />}
              children="Clear"
              onClick={evt => {
                const selectionUpdate = selectedEntries.map(a => ({...a, labels: []}));
                this.patchLabelsetEntries(labelset.id, entries, selectionUpdate);
              }}
            />
            <Box mx="auto" />
            { labelType === LabelType.Classification &&
              <ButtonGroup>
                { labelDefinitions.map((labelDefinition, index) => {
                  const [ color ] = lookupLabelDefinitionColor(labelDefinition, index);
                  const countLabels = selectedEntries.filter(a => a.labels.find(l => l.labelId === labelDefinition.id));
                  const disabled = selectedEntries.length === 0;
                  const selected = countLabels.length > 0 && countLabels.length === selectedEntries.length;
                  const indeterminate = countLabels.length > 0 && countLabels.length < selectedEntries.length;
                  return (
                    <Button key={labelDefinition.id}
                      size="small"
                      disabled={disabled}
                      variant={selected ? 'contained' : 'outlined' }
                      style={{
                        borderColor: (selected ? 'inherit' : color),
                        color: disabled ? '#DDD' : (selected ? '#FFF' : color),
                        background: selected ? color : (indeterminate ? '#DDD' : '#FFF')
                      }}
                      children={labelDefinition.name}
                      onClick={() => {
                        const selectionUpdate = toggleEmptyLabels(selectedEntries, labelDefinition, selected);
                        this.patchLabelsetEntries(labelset.id, entries, selectionUpdate);
                      }}
                    />
                  );
                  }
                )}
              </ButtonGroup>
            }
            { labelType === LabelType.BoundingBoxes &&
              <ButtonGroup>
                { labelDefinitions.map((labelDefinition, index, definitions) => (
                  <LabelDefinitionChip key={labelDefinition.id}
                    style={{margin: 5, marginLeft: 0 }}
                    labelId={labelDefinition.id}
                    labelDefinitions={definitions}
                  />
                ))}
              </ButtonGroup>
            }
            <LabelDefinitionsDialog
              labelset={labelset}
              title="Manage Label Definitions"
              trigger={
                <Tooltip title="Manage Label Definitions">
                  <IconButton size="small">
                    <SettingsIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              }
              onSuccess={labelset => {
                this.setState({ labelset });
              }}
            />
            <Box mx="auto" />
          </Toolbar>
        }
        <Box display="flex" flexDirection="row-reverse" px={2} style={{flex: '1 1 1px', overflowY: 'hidden'}}>
          <Box style={{ flex: '1 1 1px', overflowY: 'scroll' }} { ...{ref: this.containerRef}}>
            { numberOfObjects === 0 &&
              <Box display="flex" p={1} justifyContent="center"><Typography variant="body2" color="textSecondary">Dataset is empty.</Typography></Box>
            }
            { paginating &&
              <Box style={{flex: '1 1 1px', overflowY: 'scroll'}}>
                <ImageContainer
                  columns={columns}
                  aspectRatio={aspectRatio}
                >
                  {Array(100).fill(0).map((val, index) => (
                    <ImageTile key={index} />
                  ))}
                </ImageContainer>
              </Box>
            }
            { !paginating &&
              <ImageContainer
                columns={columns}
                cropBox={areaOfInterest}
                aspectRatio={aspectRatio}
                selectable
                selectableRef={this.selectableRef}
                onSelection={tiles => {
                  this.setState({ selectedIds: tiles.map(t => t.labelsetEntry!.datasetObjectId) })
                }}
              >
                { objects.map((image, index) => {
                  const labelsetEntry = entries.find(entry => entry.datasetObjectId === image.id);
                  return (
                    <ImageTile
                      key={index}
                      labelConfiguration={labelConfiguration}
                      image={image}
                      labelsetEntry={labelsetEntry}
                      isSelected={labelsetEntry && selectedEntries.includes(labelsetEntry)}
                    />
                  );
                })}
              </ImageContainer>
            }
          </Box>
          <Box>
            <ImageZoomControl
              containerRef={this.containerRef}
              cropBox={areaOfInterest}
              columns={columns}
              onChange={columns => {
                this.setState({ columns });
              }}
            />
          </Box>
        </Box>
        <Divider />
        <PaginationToolbar
          pageIndex={pageIndex}
          totalItems={numberOfObjects}
          onPageIndexChange={pageIndex => this.onPageIndexChange(labelset.datasetVersionId, labelset.id, pageIndex)}
        />
      </Box>
    );
  }
}